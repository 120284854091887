import {
    IconButton,
    InputBase,
    Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Parcel from "../Parcels/components/Parcel";
import { useStyles } from "./styles";
import { useState,/*  useCallback, */ useContext  } from "react";
import SERVICES from "services";
import { SettingsContext } from "context/Settings.context";
import Loading from "components/Loading";

const SearchParcel = () => {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState(null);
    const [loading, setLoading] = useState();
    const [parcel, setParcel] = useState(null);
    // const [confirmDialog, setConfirmDialog] = useState(null);
    const { dispatch: dispatchToast } = useContext(SettingsContext);

    const searchParcel = () => {
        setLoading(true)
        SERVICES.ORDERS.getParcelByTrackNumber(searchValue)
        .then(res => {
            setParcel(res.data[0])
        })
        .catch(err => {
            dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: err.message },
              });
        })
        .finally(() => {
            setLoading(false)
        })
    }

    // const deleteParcel = useCallback(
    //     async (item, data) => {
    //       try {
    //         const response = await SERVICES.ORDERS.deleteParcel(
    //           parcel.listId,
    //           parcel,
    //           parcel.id,
    //           data
    //         )
    //         dispatchToast({
    //           type: "SUCCESS_TOAST",
    //           payload: { message: "Success action" },
    //         });
    //         return new Promise((res) => res(response));
    //       } catch (e) {
    //         if (typeof e.message === "string") {
    //           dispatchToast({
    //             type: "ERROR_TOAST",
    //             payload: { message: e.message },
    //           });
    //         }
    //         return new Promise((_, rej) => rej(e));
    //       }
    //     },
    //     [dispatchToast, getParcels, getListById, listId, parcel]
    //   );

    // const handleParcelDelete = useCallback(
    //     (item) => {
    //       setConfirmDialog({
    //         open: true,
    //         title: "Delete",
    //         subtitle: "Are you sure you want to delete this item?",
    //         showInput: true,
    //         callback: (data) => deleteParcel(item, data),
    //       });
    //     },
    //     [deleteParcel]
    //   );


    return (
        <div
            style={{
            }}
        >
            <Paper
                component="div"
                className={classes.search}
                sx={{
                    p: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: 40,
                }}
            >
                <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => searchValue && searchParcel(searchValue)}
                >
                    <SearchIcon />
                </IconButton>
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search by tracking number"
                    onKeyDown={(e) => {
                        if(searchValue && !!searchValue.length && e.key === 'Enter'){searchValue && searchParcel(searchValue)}
                    }}
                    onChange={(e) => {
                        setParcel(null)
                        setSearchValue(e.target.value);
                    }}
                />
            </Paper>
            {loading && <Loading/>}
            <div style={{maxWidth: 700}}>
            {parcel && <Parcel 
                {...{
                    parcel,
                    handleParcelSelect: () => {},
                    state: null,
                    selected: false,
                    listId: parcel?.order_list_id,
                    handleParcelDelete: () => {}, 
                    noEditable: true,
                    listStatus: '2',
                }}
            />}
            </div>

        </div>
    )
}

export default SearchParcel