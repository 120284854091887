import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import cube from "assets/cube.png";
import calculator from '../../../assets/icons/calculator2.svg';

import arm from "assets/icons/armenia.png";
import euro from "assets/icons/euro.png";
// import russia from "assets/icons/russia.png";

// import belgium from "../../assets/icons/belgium.png";
// import germany from "../../assets/icons/germany.png";
// import france from "../../assets/icons/france.png";
// import holand from "../../assets/icons/netherlands.png";
// import bulgaria from "../../assets/icons/bulgaria.png";
// import poland from "../../assets/icons/poland.png";
// import spain from "../../assets/icons/spain.png";
// import greece from "../../assets/icons/greece.png";
// import arrows from "../../assets/icons/switch.png";
import {
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField
} from "@mui/material";
import SectionTitle from "components/SectionTitle";
import CalculatorDialog from "components/dialogs/CalculatorDialog";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import service from "services/actions.service";

const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    scrollMargin: "100px",
    margin: "100px auto",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },

    "&  .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 18,
    marginBottom: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  calculator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    paddingRight: '40px',
    fontSize: "16px",
    cursor: 'default',
    marginBottom: "20px",
    fontWeight: 'bold',
    "& .calcIcon": {
      width: "50px",
      marginTop: "20px",
      marginLeft: "20px",
      "&:hover": {
        cursor: "pointer",
      },
    }
  },
  priceInfo: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 45px 0",
    lineHeight: 2,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      flexDirection: "column",
    },
  },
  volumetricInfo: {
    display: "flex",
    "& img": {
      width: "fit-content",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  volumetricText: {
    marginRight: 50,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  table: {
    paddingTop: 45,
    boxShadow: "0px 24px 64px rgba(115, 115, 115, 0.04)",
    "& .MuiTable-root": {
      background: "#FFFFFF",
      borderRadius: 20,
    },
    "& .MuiTableCell-head": {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: 20,
      borderBottom: "1px solid rgba(93, 186, 47, 0.6) !important",
    },
    "& .MuiTableCell-root": {
      border: "none",
      textAlign: "left",
    },
    "& table": {
      whiteSpace: "nowrap",
    },
  },
  bottomSection: {
    display: "flex",
    flexDirection: "column",
    margin: "22px 0px 36px",
    textAlign: "start",
    fontSize: 12,
    fontWeight: 400,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
    padding: 8,
  },
  twoAst: {
    // display: "flex",
    // alignItems: "start",
    // textAlign: "start",
    // justifyContent: "end",
  },
  openCloseIcon: {
    "&.MuiIconButton-root": {
      color: theme.palette.primary.main,
    },
  },
  cubeAndCalculate: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  readMore: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  inputTitle: {
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },
}));

function createData(
  country_from_id,
  country_to_id,
  to_countries_name,
  country,
  delType,
  delTime,
  fixPrice,
  tariff
  // dimensions,
  // weights,
  // values
) {
  return {
    country_from_id,
    country_to_id,
    to_countries_name,
    country,
    delType,
    delTime,
    fixPrice,
    tariff,
    // dimensions,
    // weights,
    // values,
  };
}

// function createCountry(leftImg, arrows, rightImg, fromTo) {
//   return (
//     <div style={{ display: "flex" }}>
//       <img
//         src={leftImg}
//         alt="flag"
//         style={{ paddingRight: 12, maxHeight: 21 }}
//       />
//       <img
//         src={arrows}
//         alt="flag"
//         style={{ paddingRight: 12, maxHeight: 21 }}
//       />
//       <img
//         src={rightImg}
//         alt="flag"
//         style={{ paddingRight: 12, maxHeight: 21 }}
//       />
//       <span>{fromTo}</span>
//     </div>
//   );
// }

// const createDimensions = (...props) => {
//   return props;
// };

// const createWeight = (...props) => {
//   return props;
// };
// const createValue = (...props) => {
//   return props;
// };

function Row({ row }) {
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell></TableCell>
        <TableCell align="right">{row.country}</TableCell>
        <TableCell align="right">{row.delType}</TableCell>
        <TableCell align="right">{row.delTime}</TableCell>
        <TableCell align="right" style={{ whiteSpace: "pre" }}>
          {row.fixPrice}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

function removeRound(value) {
  return Math.round(value * 100) / 100;
}

const Prices = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [rows, setRows] = useState(null);
  const [openCalcDialog, setOpenCalcDialog] = useState(false);
  const [form, setForm] = useState({
    country_from_id: "7",
    country_to_id: "179",
  });
  const [fromCountries, setFromCountries] = useState([]);
  const [toCountries, setToCountries] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const getPriceList = useCallback(() => {
    service.getPriceList().then((res) => {
      setRows(
        res.data.map((el) => {
          return createData(
            el.country_from_id,
            el.country_to_id,
            el.to_countries_name,
            `${el.from_countries_name} - ${el.to_countries_name}`,
            `${t("prices.until")} ${removeRound(el.tariff_1_up_to_weight)}${t(
              "global.kg"
            )} - ${removeRound(el.tariff_1_price)}${el.country_from_id === "7" ? "AMD" : "€"
            }`,
            `${removeRound(el.tariff_1_up_to_weight)}${t(
              "global.kg"
            )} - ${removeRound(el.tariff_2_up_to_weight)}${t("global.kg")} - 
            ${removeRound(el.tariff_2_price)}${el.country_from_id === "7" ? "AMD" : "€"
            }/${t("global.kg")}, \n${removeRound(el.tariff_2_up_to_weight)}${t(
              "global.kg"
            )} ${t("prices.andMore")} - ${removeRound(el.tariff_3_price)}${el.country_from_id === "7" ? "AMD" : "€"
            }/${t("global.kg")}`,
            `${t("prices.deliveryText", { day: el.duration })}`
          );
        })
      );

      setFromCountries(() => {
        const options = res.data.map((el) => ({
          name: el.from_countries_name,
          value: el.country_from_id,
        }));
        return [
          ...new Map(options.map((item) => [item["name"], item])).values(),
        ];
      });
    });
  }, [t]);

  const handleFormChange = (val, name) => {
    setForm((data) => {
      return {
        ...data,
        [name]: val,
      };
    });
  };

  useEffect(() => {
    getPriceList();
  }, [getPriceList]);

  useEffect(() => {
    if (form.country_from_id && rows) {
      setSelectedPrice(null);
      setToCountries(() => {
        const options = rows
          .filter((el) => el.country_from_id === form.country_from_id)
          ?.map((el) => ({
            name: el.to_countries_name,
            value: el.country_to_id,
          }));

        return [
          ...new Map(options.map((item) => [item["name"], item])).values(),
        ];
      });
    }
  }, [form.country_from_id, rows]);

  useEffect(() => {
    if (form.country_from_id && form.country_to_id && rows) {
      const item = rows.find(
        (el) =>
          el.country_from_id === form.country_from_id &&
          el.country_to_id === form.country_to_id
      );
      setSelectedPrice(item);
    }
  }, [form.country_from_id, form.country_to_id, rows]);

  return (
    <Container
      maxWidth={"xl"}
      className={classes.fixedWidthContainer}
      id="pricelist"
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle title={t("prices.title")} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.table}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      {t("prices.country")}
                    </TableCell>
                    <TableCell align="left">
                      {t("prices.minWeight")}
                    </TableCell>
                    <TableCell align="left">
                      {t("prices.weightTariff")}
                    </TableCell>
                    <TableCell align="left">
                      {t("prices.deliveryDuration")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      "& > *": { borderBottom: "unset" },
                    }}
                  >
                    <TableCell />
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={arm}
                          alt="flag"
                          style={{ marginRight: 5, height: 20 }}
                        />
                        {t("prices.armeniaToEurope")}
                        <img
                          src={euro}
                          alt="flag"
                          style={{ marginLeft: 5, height: 13 }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {t("prices.until")} 1.45{t("global.kg")} - 5000AMD
                    </TableCell>
                    <TableCell align="right">
                      1.45{t("global.kg")} - 150{t("global.kg")} - 3500AMD/
                      {t("global.kg")}, 150{t("global.kg")}{" "}
                      {t("prices.andMore")} - 3000AMD/
                      {t("global.kg")}
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "pre" }}>
                      {t("prices.deliveryText", {
                        day: 25,
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "& > *": { borderBottom: "unset" },
                    }}
                  >
                    <TableCell />
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={euro}
                          alt="flag"
                          style={{ marginRight: 5, height: 13 }}
                        />
                        {t("prices.EuropeToArmenia")}
                        <img
                          src={arm}
                          alt="flag"
                          style={{ marginLeft: 5, height: 20 }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {t("prices.until")} 5{t("global.kg")} - 20€
                    </TableCell>
                    <TableCell align="right">
                      5{t("global.kg")} - 150{t("global.kg")} - 4€/
                      {t("global.kg")}, 150{t("global.kg")}{" "}
                      {t("prices.andMore")} - 3.5€/
                      {t("global.kg")}
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "pre" }}>
                      {t("prices.deliveryText", {
                        day: 25,
                      })}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow
                    sx={{
                      "& > *": { borderBottom: "unset" },
                    }}
                  >
                    <TableCell />
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={euro}
                          alt="flag"
                          style={{ marginRight: 5, height: 13 }}
                        />
                        {t("prices.EuropeToRussia")}
                        <img
                          src={russia}
                          alt="flag"
                          style={{ marginLeft: 5, height: 20 }}
                        />
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {t("prices.until")} 3.75{t("global.kg")} - 30€
                    </TableCell>
                    <TableCell align="right">
                      3.75{t("global.kg")} - 150{t("global.kg")} - 8€/
                      {t("global.kg")}, 150{t("global.kg")}{" "}
                      {t("prices.andMore")} - 7€/
                      {t("global.kg")}
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "pre" }}>
                      {t("prices.deliveryText", {
                        day: 35,
                      })}
                    </TableCell>
                  </TableRow> */}
                  <TableRow
                    sx={{
                      "& > *": { borderBottom: "unset" },
                    }}
                    style={{ borderTop: "1px solid rgba(0,0,0, 0.2)" }}
                  >
                    <TableCell />
                    <TableCell>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            width: 150,
                            display: "flex",
                            flexDirection: "column",
                            marginRight: 10,
                          }}
                        >
                          <span className={classes.inputTitle}>
                            {t("prices.sender")}{" "}
                            <span className={classes.asterisk}>*</span>
                          </span>
                          <TextField
                            select
                            fullWidth
                            size="small"
                            name="country_from_id"
                            value={form.country_from_id}
                            onChange={(event) => {
                              setForm((data) => ({
                                ...data,
                                country_to_id: "",
                              }));
                              handleFormChange(
                                event.target.value,
                                event.target.name
                              );
                            }}
                          >
                            {fromCountries.map((el) => (
                              <MenuItem key={el.value} value={el.value}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div
                          style={{
                            width: 150,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span className={classes.inputTitle}>
                            {t("prices.recipient")}{" "}
                            <span className={classes.asterisk}>*</span>
                          </span>
                          <TextField
                            select
                            fullWidth
                            size="small"
                            name="country_to_id"
                            value={form.country_to_id}
                            onChange={(event) => {
                              handleFormChange(
                                event.target.value,
                                event.target.name
                              );
                            }}
                            disabled={!!!form.country_from_id}
                          >
                            {toCountries.map((el) => (
                              <MenuItem key={el.value} value={el.value}>
                                {el.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="right">
                      {selectedPrice?.delType}
                    </TableCell>
                    <TableCell align="right">
                      {selectedPrice?.delTime}
                    </TableCell>
                    <TableCell align="right" style={{ whiteSpace: "pre" }}>
                      {selectedPrice?.fixPrice}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider />
              <div className={classes.bottomSection}>
                <div className={classes.priceInfo}>
                  <div>
                    <div>
                      <span className={classes.asterisk}>*</span>
                      <span className={classes.bottomSpan}>
                        {t("prices.priceInfo1")}
                      </span>
                    </div>
                    <div style={{ marginLeft: 20 }}>
                      <div className={classes.twoAst}>
                        {t("prices.priceInfo2")}
                        {/* <b> for 6kg 5.000 + 6 x 2.000 = 17.000 AMD</b> */}
                      </div>
                      <div className={classes.twoAst}>
                        {t("prices.priceInfo3")}
                        {/* <br />
                    <b> for 6kg 5.000 + 6 x 2.000 = 17.000 AMD</b> */}
                      </div>
                      <div>{t("prices.priceInfo4")}</div>
                      <div>{t("prices.priceInfo5")}</div>
                      <div>{t("prices.priceInfo6")}</div>
                    </div>
                    <div style={{ width: '80%' }}>
                      <span className={classes.asterisk}>*</span>
                      <span className={classes.bottomSpan}>
                        {t("prices.priceInfo7")}
                      </span>
                    </div>
                  </div>
                  <div className={classes.volumetricInfo}>
                    <div className={classes.volumetricText} >
                      {t("prices.priceInfo8")}
                      <div
                        className={classes.calculator} 
                      >
                        {t('menu.calculator')} <img src={calculator} alt="calculator Icon" className="calcIcon" onClick={() => setOpenCalcDialog(!openCalcDialog)} />
                      </div>
                    </div>
                    <div className={classes.cubeAndCalculate}>
                      <img src={cube} alt="img" height={120} />
                    </div>
                  </div>
                </div>
              </div>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
      <CalculatorDialog open={openCalcDialog} setOpen={setOpenCalcDialog} />
    </Container>
  );
};

export default Prices;
