import axiosClient from "../utils/axiosClient";
import api from "../api";
import i18next from "i18next";

const getPriceList = async () => {
  const response = await axiosClient.get(api.ACTIONS.PRICE_LIST);
  return response.data;
};
const createOrder = async (data) => {
  const response = await axiosClient.post(api.ACTIONS.CREATE_ORDRER, data);
  return response.data;
};

const getTrackStatuses = async () => {
  const response = await axiosClient.get(api.ACTIONS.GET_TRACK_STATUSES, {
    headers: {
      "Accept-Language": i18next.language,
    },
  });
  return response.data;
};

const getTrackStatus = async (data) => {
  const response = await axiosClient.get(api.ACTIONS.GET_TRACK_STATUS, {
    params: data,
    headers: {
      "Accept-Language": i18next.language,
    },
  });
  return response.data;

};
const service = {
  createOrder,
  getPriceList,
  getTrackStatuses,
  getTrackStatus,
};

export default service;
