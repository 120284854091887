const API = {
  COUNTRIES: "/web/v3/filter/countries",
  PAYMENT_TYPES: "/web/v3/filter/parcelsDiscountType",
  PARCEL_CONTENT_TYPES: "/web/v3/filter/parcelContentTypes",
  PARCEL_GROUP_CONTENT_TYPES: "/web/v3/filter/parcelContentGroups",
  COOPERATION_TYPES: "/web/v3/filter/cooperationTypes",
  STATES_TYPES: "/web/v3/filter/states",
  COST_TYPES: "/web/v3/filter/expenditureTypes",
};

export default API;
