import React from "react";
import { makeStyles } from "@mui/styles";
const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  formText: {
    paddingTop: 9,
    textAlign: "left",
  },
  sectionTitle: {
    fontSize: 15,
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 5,
  },
  info: {
    borderBottom: "1px solid #222",
    fontSize: 15,
    fontWeight: 600,
    height: "max-content",
    paddingBottom: 2,
    display: "flex",
    alignItems: "end",
  },
}));

const SenderReciver = ({ data }) => {
  const classes = useStyeles();
  const senderRecieverKeys = [
    {
      key: "director_firstname",
      secondKey: "director_lastname",
      title: "Name",
    },
    { key: "customer_address", title: "Address" },
    {
      key: "postal_code",
      title: "Post index",
      customStyle: { fontWeight: 600, fontSize: 15 },
    },
    {
      key: "city",
      secondKey: "states_name",
      thirdKey: "country_name",
      title: "City/Region/Country",
      customStyle: { fontWeight: 600, fontSize: 15 },
    },
    {
      key: "email",
      title: "Email",
      customStyle: { fontWeight: 600, fontSize: 15 },
    },
    { key: "telephone", title: "Phone" },
  ];
  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.formText}>
        <div className={classes.sectionTitle}>From ID:{`${data.sender.country_code}${data.sender.contract_id}`}</div>
        {senderRecieverKeys.map((el, idx) => (
          <div
            key={idx}
            className={classes.info}
            style={el.customStyle && el.customStyle}
          >
            {el.title}: {el.key && data?.sender[el.key]}
            {el.secondKey &&
              (el.secondKey === "states_name" ? "/" : " ") +
                (el.secondKey === "states_name"
                  ? data?.sender.country_code === "AM"
                    ? data?.sender[el.secondKey] ?? '-'
                    : data?.sender["other_states_name"] ?? '-'
                  : data?.sender[el.secondKey] ?? '-')}
            {el.thirdKey && "/" + data?.sender[el.thirdKey]}
          </div>
        ))}
        {data?.sender.taxpayer_id === "1" && (
          <div className={classes.info}>
            Company: {data?.sender.company_name}
          </div>
        )}
      </div>
      <div className={classes.formText} style={{ marginTop: 2 }}>
        <div className={classes.sectionTitle}>To ID:{`${data.receiver.country_code}${data.receiver.contract_id}`}</div>
        {senderRecieverKeys.map((el, idx) => (
          <div
            key={idx}
            className={classes.info}
            style={el.customStyle && el.customStyle}
          >
            {el.title}: {el.key && data?.receiver[el.key]}
            {el.secondKey &&
              (el.secondKey === "states_name" ? "/" : " ") +
                (el.secondKey === "states_name"
                  ? data?.receiver.country_code === "AM"
                    ? data?.receiver[el.secondKey] ?? '-'
                    : data?.receiver["other_states_name"] ?? '-'
                  : data?.receiver[el.secondKey] ?? '-')}
            {el.thirdKey && "/" + data?.receiver[el.thirdKey]}
          </div>
        ))}
        {data?.receiver.taxpayer_id === "1" && (
          <div className={classes.info}>
            Company: {data?.receiver.company_name}
          </div>
        )}
        <div style={{fontSize: 16, marginTop: 13, fontWeight: 600}}>Status: {data.paid == '1' ? 'paid' : 'unpaid'}</div>
      </div>
    </div>
  );
};

export default SenderReciver;
