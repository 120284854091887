import { Grid, MenuItem, TextField } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import SERVICES from "services";
import { useStyles } from "./styles";
import ChangePasswordDialog from "components/courier/courierDialogs/ChangePasswordDialog";
import SectionTitle from "components/SectionTitle";
import { SettingsContext } from "context/Settings.context";
import Loading from "components/Loading";
import { ImageUpload } from "components/ImageUpload";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import PhoneField from "components/PhoneField";
import { useTranslation } from "react-i18next";
const initialState = {
  firstname: "",
  lastname: "",
  city: "",
  address_1: "",
  phone: "",
  email: "",
  cooperation_type_id: "",
  id: "",
  company: "",
  serving_region_city: "",
  address_2: "",
  car_model: "",
  car_number: "",
  attachedWarehouses: "",
  date_added: "",
  passport_number: "",
  passportFile: [],
  driversLicense: [],
  passport_issue_date: null,
  passport_validity_period: null,
  passport_issued_by: "",
  driving_license_number: "",
  bank_name: "",
  bank_account_number: "",
};

export const countries = [
  { label: "Armenia", value: "Armenia" },
  { label: "Poland", value: "Poland" },
];

const Profile = () => {
  const classes = useStyles();
  const [form, setForm] = useState(initialState);
  const [deletedFilesIds, setDeletedFilesIds] = useState([]);
  const [passwordChangeDialog, setPasswordChangeDialog] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const { t } = useTranslation();
  const handleSubmit = () => {
    setInputErrors({});
    if (form.telephone) {
      setIsSubmiting(true);

      const newForm = {
        ...form,
        passportFile: form.passportFile.length
          ? form.passportFile.filter((el) => el.data_url).map((el) => el.file)
          : undefined,
        driversLicense: form.driversLicense.length
          ? form.driversLicense.filter((el) => el.data_url).map((el) => el.file)
          : undefined,
        deletedFilesIds: deletedFilesIds.length ? deletedFilesIds : undefined,
        passport_issue_date: moment(form.passport_issue_date).format(
          "DD-MM-YYYY"
        ),
        passport_validity_period: moment(form.passport_validity_period).format(
          "DD-MM-YYYY"
        ),
      };

      SERVICES.ORDERS.updateProfile(newForm)
        .then(() => {
          getProfile();
          dispatchToast({
            type: "SUCCESS_TOAST",
            payload: { message: "Success action" },
          });
        })
        .catch((err) => {
          if (typeof err.message === "string") {
            dispatchToast({
              type: "ERROR_TOAST",
              payload: { message: err.message },
            });
          } else {
            setInputErrors(err.message);
          }
        })
        .finally(() => {
          setIsSubmiting(false);
        });
    } else {
      if (!form.telephone)
        setInputErrors((prev) => ({
          ...prev,
          telephone: t("global.required"),
        }));
    }
  };

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFileChange = (value, name) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (name, value) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileDelete = (id) => {
    if (id) {
      setDeletedFilesIds((prev) => {
        const ids = [...prev];
        ids.push(id);
        return ids;
      });
    }
  };

  const getProfile = () => {
    SERVICES.ORDERS.getProfile()
      .then((res) => {
        setForm({
          ...res.data,
          passportFile: res.data.docs.filter((el) => el.type_id === "2"),
          driversLicense: res.data.docs.filter((el) => el.type_id === "1"),
          cooperation_type_id: res.data.cooperation_type_id || "",
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    SERVICES.FILTERS.getCooperationTypes().then((res) => {
      setOptions(res.data);
    });
  }, []);

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <ValidatorForm
        onSubmit={handleSubmit}
        onError={() => {
          if (!form.telephone)
            setInputErrors((prev) => ({
              ...prev,
              telephone: t("global.required"),
            }));
        }}
        className={classes.profile}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SectionTitle title="Courier Info" />
          </Grid>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  ID <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.id}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Type of cooperation{" "}
                  <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  select
                  size="small"
                  fullWidth
                  name="cooperation_type_id"
                  value={form.cooperation_type_id}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("global.required")]}
                >
                  {options.map((el) => (
                    <MenuItem key={el.id} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))}
                </TextValidator>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Name <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="firstname"
                  value={form.firstname}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("global.required")]}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Surname <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="lastname"
                  value={form.lastname}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("global.required")]}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <PhoneField
                  {...{
                    telephoneErr: inputErrors.telephone,
                    setInputErrors,
                    value: form.telephone,
                    setForm,
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Email <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  City/village <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="city"
                  value={form.city}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("global.required")]}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>
                  Full address <span className={classes.asterisk}>*</span>
                </div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="address_1"
                  value={form.address_1}
                  onChange={handleChange}
                  validators={["required"]}
                  errorMessages={[t("global.required")]}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Serving region/city </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.serving_region_city}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Service point address </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.address_2}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Company</div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.company}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Attached warehouses </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.attachedWarehouses}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Car number</div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="car_number"
                  value={form.car_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Car model</div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="car_model"
                  value={form.car_model}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Bank name</div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.bank_name}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Bank account number </div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={form.bank_account_number}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Registration Date</div>
                <TextValidator
                  size="small"
                  fullWidth
                  value={moment(form.date_added).format("DD-MM-YYYY")}
                  disabled
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Passport number</div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="passport_number"
                  value={form.passport_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <div className={classes.label}>Passport issue date</div>
                  <div
                    className={[
                      inputErrors.passport_issue_date ? classes.errorField : "",
                    ]}
                  >
                    <DesktopDatePicker
                      value={form.passport_issue_date}
                      inputFormat="DD-MM-YYYY"
                      name="passport_issue_date"
                      onChange={(val) => {
                        if (val && inputErrors.passport_issue_date) {
                          setInputErrors((prev) => {
                            const { passport_issue_date, ...rest } = prev;
                            return rest;
                          });
                        }
                        handleDateChange("passport_issue_date", val);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                    <p style={{ display: "none" }}>
                      {inputErrors.passport_issue_date}
                    </p>
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} lg={6}>
                <LocalizationProvider dateAdapter={MomentUtils}>
                  <div className={classes.label}>Passport expiry date </div>
                  <div
                    className={[
                      inputErrors.passport_validity_period
                        ? classes.errorField
                        : "",
                    ]}
                  >
                    <DesktopDatePicker
                      value={form.passport_validity_period}
                      inputFormat="DD-MM-YYYY"
                      name="passport_validity_period"
                      onChange={(val) => {
                        if (val && inputErrors.passport_validity_period) {
                          setInputErrors((prev) => {
                            const { passport_validity_period, ...rest } = prev;
                            return rest;
                          });
                        }
                        handleDateChange("passport_validity_period", val);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="small" fullWidth />
                      )}
                    />
                    <p style={{ display: "none" }}>
                      {inputErrors.passport_validity_period}
                    </p>
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Passport issued by</div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="passport_issued_by"
                  value={form.passport_issued_by}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={classes.label}>Driving license number</div>
                <TextValidator
                  size="small"
                  fullWidth
                  name="driving_license_number"
                  value={form.driving_license_number}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ImageUpload
                  {...{
                    label: "Upload Passport Image",
                    handleFileChange: (imageList) =>
                      handleFileChange(imageList, "passportFile"),
                    files: form.passportFile,
                    handleFileDelete,
                    defaultPath: "/views/partners/media/",
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ImageUpload
                  {...{
                    label: "Upload Driving license Image",
                    handleFileChange: (imageList) =>
                      handleFileChange(imageList, "driversLicense"),
                    files: form.driversLicense,
                    handleFileDelete,
                    defaultPath: "/views/partners/media/",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginTop: 30 }}>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </div>
                <div
                  className={classes.changePass}
                  onClick={() => setPasswordChangeDialog(!passwordChangeDialog)}
                >
                  Change Password
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </ValidatorForm>
      <ChangePasswordDialog
        open={passwordChangeDialog}
        setOpen={setPasswordChangeDialog}
      />
    </>
  );
};

export default Profile;
