import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import SERVICES from "services";

const PhoneField = ({
  telephoneErr,
  setInputErrors,
  disabled,
  value,
  setForm,
  name = 'telephone',
  isRequired = true,
  hideLabel,
}) => {
  const classes = useStyles();
  const [phoneCountries, setPhoneCountries] = useState([]);

  useEffect(() => {
    SERVICES.FILTERS.getCountries().then((res) => {
      setPhoneCountries(res.data.map((el) => el.code));
    });
  }, []);

  return (
    !!phoneCountries.length && (
      <>
        {!hideLabel && (
          <div className={classes.label}>
            Phone {name === 'sec_telephone' ? '2' : ''} {isRequired && <span className={classes.asterisk}>*</span>}
          </div>
        )}
        <div className={[telephoneErr ? classes.errorField : ""]}>
          <PhoneInput
            className={classes.phoneInput}
            international
            placeholder="Phone"
            countries={phoneCountries}
            value={value}
            onChange={(val) => {
              if (val || (!val && !isRequired)) {
                setInputErrors &&
                  setInputErrors((prev) => {
                    const isValid  = isValidPhoneNumber(val || '');
                    const { telephone, sec_telephone, ...rest } = prev;
                    const newForm = {...rest};
                    if(!isValid){
                      if(val && !isRequired){
                        newForm[name] = "Invalid phone number"
                      }else if(!val && !isRequired){
                        newForm[name] = ""
                      } else if(isRequired){
                        newForm[name] = "Invalid phone number"
                      }
                    }
                    if(telephone && name !== 'telephone'){
                      newForm["telephone"] = telephone
                    }
                    if(sec_telephone && name !== 'sec_telephone'){
                      newForm['sec_telephone'] = sec_telephone
                    }
                    return newForm;
                  });
              }
              setForm((prevState) => ({
                ...prevState,
                [name]: val || "",
              }));
            }}
            disabled={disabled}
          />
          <p style={{ display: "none" }}>{telephoneErr ? telephoneErr : 'Required field.'}</p>
        </div>
      </>
    )
  );
};

export default PhoneField;
