import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localResources from "utils/translate/local.resources";

const resources = {
  hy: {
    translation: localResources.hy,
  },
  pl: {
    translation: localResources.pl,
  },
  en:{
    translation: localResources.en,
  },
};

i18n.use(initReactI18next).init(
  {
    lng: "hy",
    fallbackLng: ["hy"],
    debug: false,
    resources,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
    parseMissingKeyHandler: () => " ",
    react: {
      wait: false,
      useSuspense: false,
    },
  },
  (err, t) => {
    if (err) return console.error(err);
  }
);

export default i18n;
