import { useState, useEffect, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
} from "@mui/material";
import "react-phone-number-input/style.css";
import SERVICES from "services";
import { SettingsContext } from "context/Settings.context";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { ImageUpload } from "components/ImageUpload";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  costHistory: {
    "&  .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiSelect-select": {
      textAlign: "left",
    },
  },
  label: {
    margin: "15px 0 5px",
    textAlign: "left",
    fontWeight: 600,
  },
  asterisk: {
    fontWeight: 700,
    letterSpacing: "0.4px",
    color: "#EF4444",
  },
}));

const initialState = {
  order_list_id: "",
  list_number: "",
  type_id: "",
  amount: "",
  km: "",
  litres_of_fuel: "",
  comment: "",
  file: null,
};

const CostDialog = ({ dialog, onClose, getCostHistory, id }) => {
  const classes = useStyles();
  const [lists, setLists] = useState([]);
  const [costTypes, setCostTypes] = useState([]);
  const [form, setForm] = useState(initialState);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [deletedFilesIds, setDeletedFilesIds] = useState([]);
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const { t } = useTranslation();
  const handleClose = () => {
    setForm(initialState);
    onClose();
  };

  const handleChange = (event) => {
    setForm((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
      ...(event.target.name === "type_id" && { km: "", litres_of_fuel: "" }),
    }));
  };

  const handleFileChange = (value) => {
    setForm((prevState) => ({
      ...prevState,
      file: value,
    }));
  };

  const handleFileDelete = (id) => {
    if (id) {
      setDeletedFilesIds((prev) => {
        const ids = [...prev];
        ids.push(id);
        return ids;
      });
    }
  };

  const handleSubmit = () => {
    setIsSubmiting(true);

    const newForm = {
      ...form,
      file: form?.file?.length
        ? form.file.filter((el) => el.data_url).map((el) => el.file)
        : undefined,
    };
    switch (dialog.dialogType) {
      case "add":
        SERVICES.COST.addCost(newForm)
          .then(() => {
            getCostHistory();
            handleClose();
            dispatchToast({
              type: "SUCCESS_TOAST",
              payload: { message: "Success action" },
            });
          })
          .catch((err) => {
            if (typeof err.message === "string") {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: err.message },
              });
            }
          })
          .finally(() => {
            setIsSubmiting(false);
          });
        break;
      case "edit":
        SERVICES.COST.editCost(
          {
            ...newForm,
            deletedFilesIds: deletedFilesIds.length
              ? deletedFilesIds
              : undefined,
          },
          id
        )
          .then(() => {
            getCostHistory();
            handleClose();
            dispatchToast({
              type: "SUCCESS_TOAST",
              payload: { message: "Success action" },
            });
          })
          .catch((err) => {
            if (typeof err.message === "string") {
              dispatchToast({
                type: "ERROR_TOAST",
                payload: { message: err.message },
              });
            }
          })
          .finally(() => {
            setIsSubmiting(false);
            setDeletedFilesIds([]);
          });
        break;
      default:
        console.log("noInfo");
    }
  };

  useEffect(() => {
    SERVICES.ORDERS.getOrders().then((res) => {
      setLists(res.data);
    });

    SERVICES.FILTERS.getCostTypes().then((res) => {
      setCostTypes(res.data);
    });
  }, []);

  useEffect(() => {
    if (id && dialog.dialogType === "edit") {
      SERVICES.COST.getCostHistoryId(id).then((res) => {
        setForm(() => {
          const data = Object.keys(initialState).reduce((newValue, el) => {
            newValue[el] = res.data[el] ?? null;
            return newValue;
          }, {});
          data.file = res.data.files;
          return data;
        });
      });
    }
  }, [id, dialog]);

  return (
    <Dialog
      open={dialog.open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      className={classes.costHistory}
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <DialogTitle>Unloading at the warehouse</DialogTitle>
        <DialogContent>
          <Grid container rowSpacing={0.5} columnSpacing={2}>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                List <span className={classes.asterisk}>*</span>
              </div>
              <TextValidator
                select
                size="small"
                fullWidth
                name="order_list_id"
                value={form.order_list_id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={[t("global.required")]}
              >
                {lists.map((el) => (
                  <MenuItem
                    key={el.id}
                    value={el.id}
                    selected={form.list_number === el.list_number}
                  >
                    {el.list_number}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Cost type <span className={classes.asterisk}>*</span>
              </div>
              <TextValidator
                select
                size="small"
                fullWidth
                name="type_id"
                value={form.type_id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={[t("global.required")]}
              >
                {costTypes.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </TextValidator>
            </Grid>
            {form.type_id === "1" && (
              <>
                <Grid item xs={12} lg={6}>
                  <div className={classes.label}>
                    Car mileage (km) <span className={classes.asterisk}>*</span>
                  </div>
                  <TextValidator
                    size="small"
                    fullWidth
                    name="km"
                    value={form.km}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={[t("global.required")]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <div className={classes.label}>
                    Spent fuel (litre){" "}
                    <span className={classes.asterisk}>*</span>
                  </div>
                  <TextValidator
                    size="small"
                    fullWidth
                    name="litres_of_fuel"
                    value={form.litres_of_fuel}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={[t("global.required")]}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>
                Price (euro) <span className={classes.asterisk}>*</span>
              </div>
              <TextValidator
                size="small"
                fullWidth
                name="amount"
                value={form.amount}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={[t("global.required")]}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className={classes.label}>Comment</div>
              <TextValidator
                size="small"
                fullWidth
                name="comment"
                value={form.comment}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ImageUpload
                {...{
                  label: "Upload File",
                  handleFileChange,
                  files: form.file,
                  handleFileDelete,
                  defaultPath: "/views/partners/media/",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#EF4444" }}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSubmiting}
            style={{ color: "#13A800" }}
          >
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default CostDialog;
