import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputBase,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import bus from "../../assets/icons/bus.png";
import delPackage from "../../assets/icons/package.png";
import SearchIcon from "@mui/icons-material/Search";
import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { useStyles } from "./styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SERVICES from "services";
import SectionTitle from "components/SectionTitle";
import Parcel from "./components/Parcel";
import ParcelUnloadingDialog from "./components/ParcelUnloadingDialog";
import ConfirmDialog from "components/ConfimDialog/ConfirmDialog";
import { SettingsContext } from "context/Settings.context";
import Loading from "components/Loading";

const Parcels = () => {
  const classes = useStyles();
  const { id: listId } = useParams();
  const location = useLocation();
  const [parcels, setParcels] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [parcelsInfo, setParcelsInfo] = useState({
    totalWeight: 0,
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [isSenderAM, setIsSenderAM] = useState(true);
  const [listInfoLoading, setListInfoLoading] = useState(true);
  const [selectedParcelIds, setSelectedParcelIds] = useState([]);
  const [dialogState, setDialogState] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const [list, setList] = useState({});
  const { dispatch: dispatchToast } = useContext(SettingsContext);
  const navigate = useNavigate();
  const componentRef = useRef({});
  let [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 4;
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const state = location.state;
  const [printType, setPrintType] = useState(localStorage.getItem('printType') || 'a')

  const onRadioChange = (val) => {
    setPrintType(val.target.value)
    localStorage.setItem('printType', val.target.value)
  }

  const handleChange = (e, p) => {
    setCurrentPage(p);
  };

  const handleParcelSelect = (id) => {
    setSelectedParcelIds((prev) => {
      const data = [...prev];
      const index = data.indexOf(id);
      if (index > -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }

      return data;
    });
  };

  const handleSelectAll = () => {
    setSelectedParcelIds((prev) => {
      if (prev.length && prev.length === parcels.length) {
        return [];
      }
      return parcels.map((el) => el.id);
    });
  };

  const getParcels = useCallback(() => {
    setLoading(true);
    setSelectedParcelIds([]);
    if (warehouseId) {
      SERVICES.ORDERS.getWarehouseParcels(listId, warehouseId)
        .then((res) => {
          setParcels(res.data);
        })
        .finally(() => setLoading(false));
    } else {
      SERVICES.ORDERS.getParcels(listId)
        .then((res) => {
          setIsSenderAM(res.data[0]?.sender_countries_name === "Armenia");
          setParcels(res.data);
          setParcelsInfo({
            totalWeight: res.data.reduce(
              (prev, current) => prev + +current.parcel_weight,
              0
            ),
            count: res.data.length,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [listId, warehouseId]);

  const getListById = useCallback(() => {
    setListInfoLoading(true);
    SERVICES.ORDERS.getOrdersById(listId)
      .then((res) => {
        setList(res.data);
      })
      .finally(() => setListInfoLoading(false));
  }, [listId]);

  const deleteParcel = useCallback(
    async (item, data) => {
      try {
        const response = await SERVICES.ORDERS.deleteParcel(
          listId,
          item,
          item.id,
          data
        );
        setSelectedParcelIds([]);
        getParcels();
        getListById();
        dispatchToast({
          type: "SUCCESS_TOAST",
          payload: { message: "Success action" },
        });
        return new Promise((res) => res(response));
      } catch (e) {
        if (typeof e.message === "string") {
          dispatchToast({
            type: "ERROR_TOAST",
            payload: { message: e.message },
          });
        }
        return new Promise((_, rej) => rej(e));
      }
    },
    [dispatchToast, getParcels, getListById, listId]
  );

  const handleParcelDelete = useCallback(
    (item) => {
      setConfirmDialog({
        open: true,
        title: "Delete",
        subtitle: "Are you sure you want to delete this item?",
        showInput: true,
        callback: (data) => deleteParcel(item, data),
      });
    },
    [deleteParcel]
  );

  useEffect(() => {
    getParcels();
  }, [getParcels]);

  useEffect(() => {
    getListById();
  }, [getListById]);

  const selectedParcels = parcels.filter((el) =>
    selectedParcelIds.includes(el.id)
  );

  const selectedParcelsTotalWeight = selectedParcels.reduce(
    (sum, a) => sum + +a.parcel_weight,
    0
  );

  const selectedParcelsDeliveryTotalPriceEUR = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '1' ? +a.price : 0),
    0
  );
  const selectedParcelsDeliveryTotalPricePL = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '2' ? +a.price : 0),
    0
  );

  const selectedParcelsCashTotalPriceEUR = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '1' ? +a.payment_cash : 0),
    0
  );

  const selectedParcelsCashTotalPricePL = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '2' ? +a.payment_cash : 0),
    0
  );

  const selectedParcelsTransferTotalPriceEUR = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '1' ? +a.payment_bank_transfer : 0),
    0
  );

  const selectedParcelsTransferTotalPricePL = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '2' ? +a.payment_bank_transfer : 0),
    0
  );

  const selectedParcelsRemainderTotalPriceEUR = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '1' ? +a.remainder : 0),
    0
  );
  const selectedParcelsRemainderTotalPricePL = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '2' ?  +a.remainder : 0),
    0
  );

  const selectedParcelsLocalDeliveryTotalPriceEUR = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '1' ? +a.local_delivery_price : 0),
    0
  );
  const selectedParcelsLocalDeliveryTotalPricePL = selectedParcels.reduce(
    (sum, a) => sum + (a.currency_type_id === '2' ? +a.local_delivery_price : 0),
    0
  );

  const totalWarehousesParcelsQuantity = list.warehouses?.reduce(
    (sum, a) => sum + +a.total_count,
    0
  );


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle title="Parcels" />
        </Grid>
        {listInfoLoading ? (
          <Loading />
        ) : (
          <>
            <Grid item xs={12} lg={5}>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>List number:</span>
                <span className={classes.infoText}>{list.list_number}</span>
              </div>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>Total weight:</span>
                <span className={classes.infoText}>{list.total_weight} kg</span>
              </div>
              <div className={classes.blocks}>
                <span className={classes.infoTitle}>Total quantity:</span>
                <span className={classes.infoText}>
                  {parcelsInfo.count + totalWarehousesParcelsQuantity} pcs
                </span>
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div
                className={classes.eachBlock}
                onClick={() => setWarehouseId(null)}
                style={{
                  border:
                    warehouseId === null
                      ? "2px solid #13A800"
                      : "1px solid #A7A7A7",
                }}
              >
                <img src={bus} alt="bus" height={50} />
                <div>
                  <span className={classes.infoTitle}>Weight: </span>
                  <span className={classes.infoText}>
                    {parcelsInfo.totalWeight.toFixed(2)} kg
                  </span>
                </div>
                <div>
                  <span className={classes.infoTitle}>Quantity: </span>
                  <span className={classes.infoText}>
                    {parcelsInfo.count} pcs
                  </span>
                </div>
              </div>
              {list.warehouses?.map((el, i) => (
                <div
                  key={i}
                  className={classes.eachBlock}
                  onClick={() => setWarehouseId(el.id)}
                  style={{
                    border:
                      warehouseId === el.id
                        ? "2px solid #13A800"
                        : "1px solid #A7A7A7",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={delPackage} alt="package" height={40} />
                      <div className={classes.warehouse}>
                        <span>
                          <span className={classes.infoTitle}>Warehouse: </span>
                          <span className={classes.infoText}>
                            {el.warehouses_name}
                          </span>
                        </span>
                        <span>
                          <span className={classes.infoTitle}>Weight: </span>
                          <span className={classes.infoText}>
                            {el.total_weight}
                          </span>
                        </span>
                        <span>
                          <span className={classes.infoTitle}>Quantity: </span>
                          <span className={classes.infoText}>
                            {el.total_count} pcs
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Grid>
          </>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Paper
            component="div"
            className={classes.search}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 40,
            }}
          >
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              disabled
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              onChange={(e) => {
                setCurrentPage(1);
                setSearchValue(e.target.value);
              }}
            />
          </Paper>
        </div>
        <Grid item xs={12}>
          <div className={classes.totalBlock}>
            <FormGroup>
              <FormControlLabel
                label="All"
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={
                      !!selectedParcelIds.length &&
                      selectedParcelIds.length === parcels.length
                    }
                    indeterminate={
                      !!selectedParcelIds.length &&
                      selectedParcelIds.length !== parcels.length
                    }
                    onChange={handleSelectAll}
                  />
                }
              />
            </FormGroup>
            <div className={classes.total} style={{alignItems: 'center'}}>
            <div className={classes.eachTotal}>
                  <span>
                    Total Weight <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsTotalWeight.toFixed(2)} kg
                  </span>
                </div>
                <div  style={{ flexDirection: 'column' }}>
              <dix style={{ display: 'flex' }}>
                <div className={classes.eachTotal}>
                  <span>
                    Delivery Price <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsDeliveryTotalPriceEUR.toFixed(2)} EU
                  </span>
                </div>
                <div className={classes.eachTotal}>
                  <span>
                    Cash <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsCashTotalPriceEUR.toFixed(2)} EU
                  </span>
                </div>

                <div className={classes.eachTotal}>
                  <span>
                    Bank transfer <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsTransferTotalPriceEUR.toFixed(2)} EU
                  </span>
                </div>

                <div className={classes.eachTotal}>
                  <span>
                    Payment in {isSenderAM ? "EU" : "Armenia"}
                    <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsRemainderTotalPriceEUR.toFixed(2)} EU
                  </span>
                </div>
                <div className={classes.eachTotal}>
                  <span>
                    Internal delivery <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsLocalDeliveryTotalPriceEUR.toFixed(2)} EU
                  </span>
                </div>
              </dix>
              <div style={{ display: 'flex' }}>
                <div className={classes.eachTotal}>
                   <span>
                     Delivery Price <Divider className={classes.divider} />
                   </span>
                   <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                     {selectedParcelsDeliveryTotalPricePL.toFixed(2)} ZL
                   </span>
                 </div>
                <div className={classes.eachTotal}>
                  <span>
                    Cash  <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsCashTotalPricePL.toFixed(2)} ZL
                  </span>
                </div>
                <div className={classes.eachTotal}>
                  <span>
                    Bank transfer <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsTransferTotalPricePL.toFixed(2)} ZL
                  </span>
                </div>
                <div className={classes.eachTotal}>
                  <span>
                    Payment in {isSenderAM ? "EU" : "Armenia"} 
                    <Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsRemainderTotalPricePL.toFixed(2)} ZL
                  </span>
                </div>
                <div className={classes.eachTotal}>
                  <span>
                    Internal delivery<Divider className={classes.divider} />
                  </span>
                  <span style={{ fontSize: 24, fontWeight: 600, marginTop: 5 }}>
                    {selectedParcelsLocalDeliveryTotalPricePL.toFixed(2)} ZL
                  </span>
                </div>
              </div>
              </div>
            </div>
            <div>
              <FormControl>
                <div style={{ textAlign: 'left', fontWeight: 900 }}>Print</div>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={onRadioChange}
                >
                  <FormControlLabel value="a" checked={printType === 'a'} control={<Radio style={{ color: printType === 'a' && '#13A800' }} />} label="A4" />
                  <FormControlLabel value="b" checked={printType === 'b'} control={<Radio style={{ color: printType === 'b' && '#13A800' }} />} label="A6" />
                </RadioGroup>
              </FormControl>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => setDialogState(true)}
                disabled={
                  !(selectedParcelIds.length > 0) ||
                  !!warehouseId ||
                  list.status === "2"
                }
              >
                Unload
              </Button>
              {list.status !== "2" && (
                <Button
                  variant="contained"
                  className={classes.btn}
                  onClick={() => navigate(`/parcelReservation/${listId}`)}
                >
                  + Add
                </Button>
              )}
            </div>
          </div>
        </Grid>
        {loading ? (
          <Loading />
        ) : (
          <>
            {(searchValue
              ? parcels.filter((el) =>
                (el.tracking_code + el.tracking_number)
                  .toLowerCase()
                  .includes(searchValue.toLowerCase())
              )
              : parcels
            )
              .slice(indexOfFirstRecord, indexOfLastRecord)
              .map((parcel, idx) => (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={parcel.id}
                  ref={(ref) => (componentRef.current[idx] = ref)}
                >
                  <Parcel
                    {...{
                      parcel,
                      handleParcelSelect,
                      state,
                      selected: selectedParcelIds.includes(parcel.id),
                      listId,
                      printType,
                      handleParcelDelete,
                      noEditable: !!warehouseId,
                      listStatus: list.status,
                      // reactToPrintContent: () => reactToPrintContent(idx),
                    }}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <Pagination
                className={classes.pagination}
                count={Math.ceil(
                  (searchValue
                    ? parcels.filter((el) =>
                      (el.tracking_code + el.tracking_number)
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                    ).length
                    : parcels.length) / recordsPerPage
                )}
                size="large"
                page={currentPage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      <ParcelUnloadingDialog
        {...{
          open: dialogState,
          onClose: () => setDialogState(false),
          selectedParcelIds,
          getParcels,
          getListById,
          emptyParcels: () => setSelectedParcelIds([]),
          listId,
        }}
      />
      <ConfirmDialog dialog={confirmDialog} setDialog={setConfirmDialog} />
    </>
  );
};

export default Parcels;
