import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import bag_icon from "assets/icons/bag.png";
import delivery_icon from "assets/icons/delivery_icon.png";
import { useStyles } from "./styles";
import SERVICES from "services";
import { useTranslation } from "react-i18next";
import { currencyTypes } from "pages/Parcels/components/Parcel";

function getIntDeliveryPrice(form, sameAddress = true, parcelID, setChanged) {
  const localDeliveryAddress = localStorage.getItem("localDeliveryAddress");
  const localDeliveryCity = localStorage.getItem("localDeliveryCity");
  const localDeliveryStatesId = localStorage.getItem("localDeliveryStatesId");
  const localDeliveryAddressCount = localStorage.getItem(
    "localDeliveryAddressCount"
  );

  let intDeliveryPayment =
    form.local_delivery_type === 1 && form.local_delivery_states_id
      ? form.local_delivery_states_id === "1"
        ? Number(form.parcel_weight) > 30
          ? 10
          : 5
        : Number(form.parcel_weight) > 30
          ? 15
          : 7
      : 0;

  if (
    form.local_delivery_type === 3 &&
    form.local_delivery_full_address === "ք․ Գյումրի, Գյումրու գրասենյակ"
  ) {
    intDeliveryPayment = Number(form.parcel_weight) > 30 ? 10 : 5;
  }
  const isSameAddress =
    localDeliveryAddress &&
    localDeliveryCity?.toLowerCase() ===
    form.local_delivery_city?.toLowerCase() &&
    localDeliveryAddress?.toLowerCase() ===
    form.local_delivery_full_address?.toLowerCase() &&
    localDeliveryStatesId === form.local_delivery_states_id;

  let isLastPrice = false;
  if (form.defaultForm) {
    for (let [key, value] of Object.entries(form.defaultForm)) {
      if (
        String(form[key]).toLowerCase() !== String(value).toLowerCase() &&
        key !== "local_delivery_price"
      ) {
        isLastPrice = false;
        break;
      }
      isLastPrice = true;
    }
  }
  if (isLastPrice) {
    setChanged(true);
  } else {
    setChanged(false);
  }
  if (isSameAddress && sameAddress && !parcelID) {
    if (localDeliveryAddressCount === "1") {
      intDeliveryPayment = 0;
    } else if (localDeliveryAddressCount > 1) {
      intDeliveryPayment = 1;
    }
  } else if (isLastPrice) {
    intDeliveryPayment = Number(form.defaultForm.local_delivery_price);
  }
  if (form.local_delivery_type === 2) {
    intDeliveryPayment = 0;
  }
  return intDeliveryPayment;
}

const InternalDelivery = ({
  form,
  handleChange,
  parcel_weight,
  disabled,
  setIsPressed,
  isPressed,
  setForm,
  defForm,
  setChanged,
  customerId,
  parcelId,
  currency_type_id
}) => {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const { t } = useTranslation();
  const [recipientAddress, setRecipientAddress] = useState({
    country_code: "",
    address: "",
    states_id: null,
  });


  const currency = currencyTypes[form.currency_type_id]
  const localDeliveryAddress = localStorage.getItem("localDeliveryAddress");
  const localDeliveryStatesId = localStorage.getItem("localDeliveryStatesId");
  const localDeliveryCity = localStorage.getItem("localDeliveryCity");
  const localDeliveryAddressCount = localStorage.getItem(
    "localDeliveryAddressCount"
  );

  let intDeliveryPaymentPrice =
    form.local_delivery_type === 1 && form.local_delivery_states_id
      ? form.local_delivery_states_id === "1"
        ? Number(form.parcel_weight) > 30
          ? 10
          : 5
        : Number(form.parcel_weight) > 30
          ? 15
          : 7
      : 0;

  if (
    form.local_delivery_type === 3 &&
    form.local_delivery_full_address === "ք․ Գյումրի, Գյումրու գրասենյակ"
  ) {
    intDeliveryPaymentPrice = Number(form.parcel_weight) > 30 ? 10 : 5;
  }

  if (localDeliveryAddressCount === "1") {
    intDeliveryPaymentPrice = 0;
  } else if (localDeliveryAddressCount > 1) {
    intDeliveryPaymentPrice = 1;
  }

  useEffect(() => {
    SERVICES.FILTERS.getStates().then((res) => {
      setStates(res.data);
    });
  }, []);

  useEffect(() => {
    if (customerId) {
      SERVICES.CUSTOMERS.getCustomers({ customer_id: customerId }).then(
        (res) => {
          const customer = res.data[0];
          setRecipientAddress({
            address: customer.customer_address,
            states_id: customer.states_id,
            city: customer.city,
            country_code: customer.country_code,
          });
          if (customer.country_code !== "AM") {
            setForm({ ...defForm, parcel_weight, parcel_local_delivery: 2 });
          }
        }
      );
    } else {
      setRecipientAddress({
        country_code: "",
        address: "",
        city: "",
        states_id: null,
      });
    }
  }, [customerId, setForm, defForm]);

  return (
    <>
      <Grid item xs={12}>
        <div className={classes.titleContainer}>
          <div className={classes.title}>
            <img src={delivery_icon} alt="icon" className={classes.icon} />
            <span>Internal delivery</span>
          </div>
          {!disabled && (
            <Button
              variant="outlined"
              disabled={recipientAddress.country_code !== "AM"}
              style={{
                opacity: recipientAddress.country_code !== "AM" && 0.5,
              }}
              className={
                form.parcel_local_delivery === "1"
                  ? classes.removeBtn
                  : classes.btn
              }
              onClick={() => {
                if (form.parcel_local_delivery === "1") handleChange({}, true);
                handleChange(
                  {
                    target: {
                      name: "parcel_local_delivery",
                      value: form.parcel_local_delivery === "1" ? "2" : "1",
                    },
                  },
                  false,
                  {
                    local_delivery_address_type: "1",
                  }
                );
              }}
            >
              {form.parcel_local_delivery === "1" ? "Remove" : "Add"}
            </Button>
          )}
        </div>
      </Grid>
      {form.parcel_local_delivery === "1" && (
        <>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Delivery province <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              select
              size="small"
              fullWidth
              name="local_delivery_states_id"
              value={form.local_delivery_states_id || ""}
              onChange={(event) => {
                setIsPressed(false);
                handleChange(
                  {
                    target: {
                      value: getIntDeliveryPrice(
                        handleChange(event, false, {}),
                        false,
                        parcelId,
                        setChanged
                      ),
                      name: "local_delivery_price",
                    },
                  },
                  false,
                  {}
                );
              }}
              validators={["required"]}
              errorMessages={['Required field.']}
              disabled={disabled}
            >
              {states.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextValidator>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              City/village <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="local_delivery_city"
              value={form.local_delivery_city || ""}
              onChange={(event) => {
                setIsPressed(false);
                handleChange(
                  {
                    target: {
                      value: getIntDeliveryPrice(
                        handleChange(event, false, {}),
                        false,
                        parcelId,
                        setChanged
                      ),
                      name: "local_delivery_price",
                    },
                  },
                  false,
                  {}
                );
              }}
              validators={["required"]}
              errorMessages={['Required field.']}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Full address <span className={classes.asterisk}>*</span>
            </div>
            <TextValidator
              size="small"
              fullWidth
              name="local_delivery_full_address"
              value={form.local_delivery_full_address || ""}
              onChange={(event) => {
                setIsPressed(false);
                handleChange(
                  {
                    target: {
                      value: getIntDeliveryPrice(
                        handleChange(event, false, {}),
                        false,
                        parcelId,
                        setChanged
                      ),
                      name: "local_delivery_price",
                    },
                  },
                  false,
                  {}
                );
              }}
              validators={["required"]}
              errorMessages={['Required field.']}
              disabled={disabled}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              {/* {recipientAddress.country_code === "AM" &&
                !disabled &&
                (recipientAddress.address?.toLowerCase() !==
                  form.local_delivery_full_address?.toLowerCase() ||
                  recipientAddress.city?.toLowerCase() !==
                  form.local_delivery_city?.toLowerCase() ||
                  Number(form.local_delivery_states_id) !==
                  Number(recipientAddress.states_id)) && (
                  <Card
                    sx={{ maxWidth: 275, marginTop: "15px" }}
                    className={classes.addressCard}
                  >
                    <CardContent style={{ paddingBottom: 0 }}>
                      <Typography
                        sx={{ fontSize: 16, fontWeight: "bold" }}
                        gutterBottom
                      >
                        ք․ Գյումրի, Գյումրու գրասենյակ
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          const formValues = {
                            local_delivery_full_address:
                              "ք․ Գյումրի, Գյումրու գրասենյակ",
                            local_delivery_address_type: "3",
                            local_delivery_states_id: 8,
                            parcel_weight,
                            local_delivery_type: 3,
                            defaultForm: form.defaultForm,
                          };
                          setIsPressed(false);
                          handleChange(
                            {
                              target: {
                                value: "ք․ Գյումրի, Գյումրու գրասենյակ",
                                name: "local_delivery_full_address",
                              },
                            },
                            false,
                            {
                              local_delivery_states_id: 8,
                              local_delivery_city: "Գյումրի",
                              local_delivery_type: 3,
                              local_delivery_price: getIntDeliveryPrice(
                                formValues,
                                true,
                                parcelId,
                                setChanged
                              ),
                            }
                          );
                        }}
                      >
                        Use Gyumri office address
                      </Button>
                    </CardActions>
                  </Card>
                )} */}
              {!!localDeliveryAddress && !disabled && !!!parcelId && (
                <Card
                  sx={{ maxWidth: 275, marginTop: "15px" }}
                  className={classes.addressCard}
                >
                  <CardContent style={{ paddingBottom: 0 }}>

                    <>
                      <div style={{fontSize: 17, fontWeight: "bold"}}>
                        <Typography
                          sx={{ fontSize: 17, fontWeight: "bold" }}
                          gutterBottom
                        >
                          {localDeliveryAddress}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: 20,
                          }}
                        >
                          <Typography
                            sx={{ fontSize: 17, fontWeight: "bold" }}
                            gutterBottom
                          >
                            {localDeliveryAddressCount}
                          </Typography>
                          <img src={bag_icon} alt="icon" />
                        </div>
                        <div>
                        <Typography
                          sx={{ fontSize: 17, fontWeight: "bold" }}
                          gutterBottom
                        >
                          {Number(intDeliveryPaymentPrice).toFixed(2)} €
                        </Typography>
                          
                        </div>
                      </div>
                    </>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        const formValues = {
                          local_delivery_full_address: localDeliveryAddress,
                          local_delivery_address_type: "2",
                          local_delivery_states_id: localDeliveryStatesId,
                          local_delivery_city: localDeliveryCity,
                          local_delivery_type: form.local_delivery_type || 1,
                          defaultForm: form.defaultForm,
                        };
                        setIsPressed(true);
                        handleChange(
                          {
                            target: {
                              value: localDeliveryAddress,
                              name: "local_delivery_full_address",
                            },
                          },
                          false,
                          {
                            local_delivery_address_type: "2",
                            local_delivery_states_id: localDeliveryStatesId,
                            local_delivery_city: localDeliveryCity,
                            local_delivery_type: 1,
                            local_delivery_price: getIntDeliveryPrice(
                              formValues,
                              true,
                              parcelId,
                              setChanged
                            ),
                          }
                        );
                      }}
                    >
                      Use the last entered address
                    </Button>
                  </CardActions>
                </Card>
              )}
              {recipientAddress.country_code === "AM" &&
                !disabled &&
                (recipientAddress.address?.toLowerCase() !==
                  form.local_delivery_full_address?.toLowerCase() ||
                  recipientAddress.city?.toLowerCase() !==
                  form.local_delivery_city?.toLowerCase() ||
                  Number(form.local_delivery_states_id) !==
                  Number(recipientAddress.states_id)) && (
                  <Card
                    sx={{ maxWidth: 275, marginTop: "15px" }}
                    className={classes.addressCard}
                  >
                    <CardContent style={{ paddingBottom: 0 }}>
                      <Typography
                        sx={{ fontSize: 17, fontWeight: "bold" }}
                        gutterBottom
                      >
                        {recipientAddress.address}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        onClick={() => {
                          const formValues = {
                            local_delivery_full_address:
                              recipientAddress.address,
                            local_delivery_address_type: "1",
                            local_delivery_states_id:
                              recipientAddress.states_id,
                            local_delivery_type: 1,
                            parcel_weight,
                            defaultForm: form.defaultForm,
                          };
                          setIsPressed(false);

                          handleChange(
                            {
                              target: {
                                value: recipientAddress.address,
                                name: "local_delivery_full_address",
                              },
                            },
                            false,
                            {
                              local_delivery_states_id:
                                recipientAddress.states_id,
                              local_delivery_city: recipientAddress.city,
                              local_delivery_type: 1,
                              local_delivery_price: getIntDeliveryPrice(
                                formValues,
                                true,
                                parcelId,
                                setChanged
                              ),
                            }
                          );
                        }}
                      >
                        Use recipient address
                      </Button>
                    </CardActions>
                  </Card>
                )}
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Internal delivery type <span className={classes.asterisk}>*</span>
            </div>
            <Select
              size="small"
              fullWidth
              name="local_delivery_type"
              value={form.local_delivery_type || null}
              onChange={(event) =>
                handleChange(
                  {
                    target: {
                      value: getIntDeliveryPrice(
                        handleChange(event, false, {}),
                        false,
                        parcelId,
                        setChanged
                      ),
                      name: "local_delivery_price",
                    },
                  },
                  false,
                  {}
                )
              }
              disabled={disabled || isPressed}
            >
              <MenuItem value={1}>Standart</MenuItem>
              <MenuItem value={2}>Free</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={classes.label}>
              Internal delivery payment{" "}
              <span className={classes.asterisk}>*</span>
            </div>
            {/* <Select size="small" fullWidth value="eur" disabled>
              <MenuItem value={"eur"}>EUR</MenuItem>
            </Select> */}
            <Select size="small" fullWidth value={currencyTypes[currency_type_id]} disabled>
              <MenuItem value={currencyTypes[currency_type_id]}>{currencyTypes[currency_type_id].toUpperCase()}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} style={{ fontWeight: 600, padding: "20px 0" }}>
            <div>Payment amount</div>
            <div style={{ fontSize: 22, marginTop: 10 }}>
              {/* {Number(form.local_delivery_price || 0).toFixed(2)} € */}
              {Number(form.local_delivery_price || 0).toFixed(2)} {currencyTypes[currency_type_id + 'symbol']} 
            </div>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity="warning"
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              Price to Yerevan - 5 {/* euro */}{currency}, includes 2 pieces of delivery to one
              address, each additional piece 1 {/* euro */}{currency} <br />
              Cost for Regions - 7 {/* euro */}{currency}, includes 2 pieces of delivery to one
              address, each additional piece 1 {/* euro */}{currency} <br />
              Price to Gyumri office - 5 {/* euro */}{currency}, includes 2 pieces of delivery,
              each additional piece 1 {/* euro */}{currency}
            </Alert>
          </Grid>
        </>
      )}
    </>
  );
};

export default InternalDelivery;
