import { useRef } from "react";
import { Button, Checkbox, Divider, Grid, IconButton } from "@mui/material";
import ReactToPrint from "react-to-print";
import trash_icon from "../../../assets/icons/trash.png";
import from_icon from "../../../assets/icons/from.png";
import to_icon from "../../../assets/icons/to.png";
import location_icon from "../../../assets/icons/location.png";
import edit_icon from "../../../assets/icons/edit.png";
import print_icon from "../../../assets/icons/print.png";
import { useStyles } from "./styles";
import moment from "moment";
import ComponentToPrint from "./ComponentToPrint.js";
import "./ParcelStyle.css";
import { useNavigate } from "react-router-dom";

export const currencyTypes = {
   1: "euro",
   2: "zloty",
   "1short": "EU",
   "2short": "ZL",
   "1symbol": "€",
   "2symbol": "ZL",
}

const Parcel = ({
  parcel,
  handleParcelSelect,
  selected,
  listId,
  printType,
  handleParcelDelete,
  state,
  noEditable,
  listStatus,
  // reactToPrintContent,
}) => {
  const classes = useStyles();
  const compRef = useRef({});
  const clickRef = useRef(null);
  const navigate = useNavigate();

  const reactToPrintTrigger = () => {
    if (
      state &&
      localStorage.getItem("print") &&
      String(state) === String(parcel.id)
    ) {
      setTimeout(() => {
        clickRef.current?.click();
        localStorage.removeItem("print");
      }, 1000);
    }
    return (
        <Button
          variant="contained"
          className={classes.printBtn}
          onClick={() => navigate(`/parcelReservation/${listId}`)}
          ref={clickRef}
        >
          <img src={print_icon} alt="icon" className={classes.icon} />
          Print
        </Button>
    );
  };


  return (
    <>
      <div className={`${classes.box} box`} ref={compRef}>
        <div style={{ width: printType === 'b' ? "100%" : "95%" }} className="print">
          <ComponentToPrint
            listId={listId}
            printType={printType}
            id={parcel.id}
          />
        </div>
        <div className="noPrint">
          <div className={classes.track}>
            <div style={{ display: "flex" }}>
              <Checkbox
                className={classes.checkbox}
                checked={selected}
                onChange={() => handleParcelSelect(parcel.id)}
              />
              <div className={classes.trackNumberBlock}>
                <span className={classes.trackingTitle}>Tracking Number: </span>
                <span className={classes.info}>
                  {parcel.tracking_code + parcel.tracking_number}
                </span>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              {listStatus !== "2" && (
                <IconButton
                  onClick={() =>
                    navigate(
                      `/parcelReservation/${listId}/${parcel.id}` +
                        (noEditable ? "/1" : "")
                    )
                  }
                >
                  <img src={edit_icon} alt="icon" />
                </IconButton>
              )}
              {!noEditable && listStatus !== "2" && (
                <IconButton onClick={() => handleParcelDelete(parcel)}>
                  <img src={trash_icon} alt="icon" />
                </IconButton>
              )}
            </div>
          </div>
          <div className={classes.sendRec}>
            <span>Sender</span>
            <span>Recipient</span>
          </div>
          <Divider />
          <div className={classes.sendTo}>
            <div>
              <div className={classes.textIcon}>
                <img src={from_icon} alt="icon" className={classes.icon} />
                <span>{parcel.sender_name}</span>
              </div>
              <div className={classes.textIcon}>
                <img
                  src={location_icon}
                  alt="location"
                  className={classes.icon}
                />
                <span>{parcel.sender_countries_name}</span>
              </div>
            </div>
            <div>
              <div className={classes.textIcon}>
                <img src={to_icon} alt="icon" className={classes.icon} />
                <span>{parcel.receiver_name}</span>
              </div>
              <div className={classes.textIcon}>
                <img
                  src={location_icon}
                  alt="location"
                  className={classes.icon}
                />
                <span>{parcel.receiver_countries_name}</span>
              </div>
            </div>
          </div>
          <div className={classes.delInfo}>
            <Grid container spacing={2}>
              <Grid item lg={3} sm={6} xs={12}>
                <div className={classes.eachInfo}>
                  <span className={classes.titleSpan}>Created date</span>
                  <span className={classes.info}>
                    {moment(parcel.date_added).format("DD-MM-YYYY")}
                  </span>
                </div>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <div className={classes.eachInfo}>
                  <span className={classes.titleSpan}>Weight</span>
                  <span className={classes.info}>
                    {parcel.parcel_weight} kg
                  </span>
                </div>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <div className={classes.eachInfo}>
                  <span className={classes.titleSpan}>Value</span>
                  <span className={classes.info}>
                    {parcel.parcel_declared_value} {currencyTypes[parcel.currency_type_id + "short"]}
                  </span>
                </div>
              </Grid>
              <Grid item lg={3} sm={6} xs={12}>
                <div className={classes.eachInfo}>
                  <span className={classes.titleSpan}>Delivery Price</span>
                  <span className={classes.info}>{parcel.price} {currencyTypes[parcel.currency_type_id + "short"]} </span>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.courierInfo}>
            <div>
              <span>Courier: </span>
              <span style={{ fontWeight: 600 }}>
                {parcel.partnerFirstname + " " + parcel.partnerLastname}
              </span>
            </div>
            <div>
               <ReactToPrint
                 content={() => compRef.current}
                 trigger={reactToPrintTrigger}

               />
               {/* <ReactToPrint
                 content={() => compRef.current}
                 trigger={reactToPrintTrigger}
               /> */}
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Parcel;
