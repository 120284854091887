import { useState, useContext, useEffect, useCallback } from "react";
import {
  Button,
  CircularProgress,
  Container,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import main from "assets/main1.jpg";
import addIcon from "assets/icons/addIcon.png";
import search from "assets/icons/searchIcon.png";
import mainMobile from "assets/mainMobile.png";
import { useParams } from "react-router-dom";
import AddOrderDialog from "./components/AddOrderDialog";
import OrderDetailsDialog from "./components/OrderDetailsDialog";
import { SettingsContext } from "context/Settings.context";
import SERVICES from "services";
import { useTranslation } from "react-i18next";
import QR1 from "../../../../assets/Barekan QR 1.png";
import QR2 from "../../../../assets/Barekan QR 3.png";
import i18next from "i18next";
const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    scrollMargin: "100px",
    textAlign: "center",
    height: 500,
    backgroundSize: "cover",
    background: `radial-gradient(88.99% 318.84% at -14.03% 100%, #000000 0%, rgba(33, 56, 25, 0.38) 100%), url(${main}) bottom`,
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      background: `radial-gradient(88.99% 318.84% at -14.03% 100%, #000000 0%, rgba(33, 56, 25, 0.38) 100%), url(${main}) bottom`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: 350,
    },
  },
  overImage: {
    display: "flex",
    flexDirection: "column",
    padding: "100px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "85px 0px",
    },
  },

  header: {
    textAlign: "left",
    color: "#fff",
    fontStyle: "italic",
    fontWeight: 700,
    fontSize: 38,
    width: "100%",
    lineHeight: 1.5,
    paddingBottom: 10,
    maxWidth: 700,
    [theme.breakpoints.down("md")]: {
      fontSize: 28,
    },
  },
  p: {
    width: 490,
    fontSize: 20,
    textAlign: "left",
    color: "#fff",
    paddingBottom: 35,
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  addBtn: {
    maxWidth: 195,
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 16,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: "unset",
      width: "100%",
      padding: "5px 20px",
    },
  },
  orderBlock: {
    display: "flex",
    flexDirection: "column",
    margin: "70px auto",
    [theme.breakpoints.down("md")]: {
      margin: "30px 15px",
    },
  },
  trackTitle: {
    display: "flex",
    fontWeight: 600,
    fontSize: 40,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(1042)]: {
      fontSize: 24,
      display: "flex",
      justifyContent: "left",
      alignItems: "left",
      textAlign: "left",
    },
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: 24,
    //   width:300
    // },
  },
  trackOrder: {
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    width: "100%",
    maxWidth: 1440,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  flexx: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down(1042)]: {
      flexDirection: "column",
    },
  },
  left: {
    width: "100%",
    backgroundColor: "#fff",
    // maxWidth: 496,
    textAlign: "left",
    // marginBottom: 20,
    // marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      paddingBottom: 15,
    },
  },
  right: {
    width: "100%",
    maxWidth: 500,
    textAlign: "left",
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
    [theme.breakpoints.down(1042)]: {
      textAlign: "left",
      justifyContent: "left",
      width: "100%",
      maxWidth: 900,
    },
  },
  rights: {
    width: "100%",
    maxWidth: 500,
    textAlign: "left",
    display: "flex",
    justifyContent: "end",
    marginTop: 20,
    [theme.breakpoints.down(1042)]: {
      textAlign: "left",
      justifyContent: "right",
      width: "100%",
      maxWidth: 900,
    },
  },
  searchInput: {
    background: "#FFFFFF",
    width: "100%",
    maxWidth: 500,
    height: 58,
    fontSize: 18,
    position: "relative",
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#13A800",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down(1042)]: {
      width: "100%",
      maxWidth: 900,
    },
  },
  trackInput: {
    "&.MuiButtonBase-root": {
      width: 180,
      height: 55,
      zIndex: 5,
      position: "absolute",
      textTransform: "unset",
      backgroundColor: "#13A800",
      color: "white",
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 16,
      padding: "5px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
      "&.Mui-disabled": {
        pointerEvents: "auto",
        cursor: "not-allowed",
        opacity: 0.7,
      },
      [theme.breakpoints.down("md")]: {
        width: "unset",
      },
    },
  },
  track: {
    fontWeight: 500,
    fontSize: 20,
    color: "#fff",
  },
  textdiv: {
    display: "flex",
    width: "100%",
    maxWidth: 500,
    textAlign: "left",
    [theme.breakpoints.down(1042)]: {
      textAlign: "left",
      justifyContent: "left",
      width: "100%",
      maxWidth: 900,
    },
  },
  searchBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      borderRadius: 12,
      fontWeight: 400,
      fontSize: 18,
      height: 58,
      padding: "5px 30px",
      margin: "0px 30px",
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
}));
const Main = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { dispatch } = useContext(SettingsContext);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [trackInfo, setTrackInfo] = useState({
    trackNumber: "",
    trackStatuses: [],
    trackStatus: {},
  });
  const { trackNumber } = useParams();

  const handleClick = () => {
    getTrackStatuses(trackInfo.trackNumber);
  };

  const getTrackStatuses = useCallback(
    (trackNum) => {
      setSubmitLoading(true);
      let trackStatuses;
      SERVICES.ACTIONS.getTrackStatuses().then((res) => {
        trackStatuses = res.data;
        SERVICES.ACTIONS.getTrackStatus({
          track_number: trackNum,
        })
          .then((res) => {
            setTrackInfo((prev) => ({
              ...prev,
              trackStatus: res.data,
              trackStatuses,
            }));
          })
          .catch((err) =>
            dispatch({
              type: "ERROR_TOAST",
              payload: { message: err.message },
            })
          )
          .finally(() => setSubmitLoading(false));
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (trackNumber) {
      getTrackStatuses(trackNumber);
      setTrackInfo({ trackNumber });
    }
  }, [trackNumber, getTrackStatuses]);
  // const qrImage = i18next.language === "hy" ? QR2 : QR1;
  return (
    <>
      <div className={classes.container} id="home">
        <Container maxWidth={"xl"}>
          <div className={classes.overImage}>
            <span className={classes.header}>{t("main.title")}</span>
            <span className={classes.p}>{t("main.subTitle")}</span>
            <Button
              className={classes.addBtn}
              onClick={() => setOpenOrderDialog(!openOrderDialog)}
            >
              <img src={addIcon} alt="add" style={{ paddingRight: 15 }} />
              {t("main.btnText")}
            </Button>
          </div>
        </Container>
      </div>
      <Container maxWidth={"xl"} className={classes.orderBlock}>
        <div className={classes.flexx}>
          <div className={classes.left}>
            {/* <img
              src={qrImage}
              alt="/"
              style={{ width: "100%", maxWidth: 450, height: 130 }}
            /> */}
            <div className={classes.textdiv}>
              <span className={classes.trackTitle}>
                {t("main.trackCourierTitle")}
              </span>
            </div>
            <div className={classes.right} style={{ marginBottom: 10 }}>
              <span>{t("main.trackCourierText")}</span>
            </div>
          </div>
          <div className={classes.trackOrder}>
           
            <div className={classes.rights}>
              <TextField
                id="outlined-basic"
                value={trackInfo?.trackNumber || ""}
                placeholder={"AMPL0000000"}
                className={classes.searchInput}
                disabled={submitLoading}
                onChange={(event) => {
                  setTrackInfo((prev) => ({
                    ...prev,
                    trackNumber: event.target.value,
                  }));
                }}
              />
              <Button
                className={classes.trackInput}
                onClick={handleClick}
                disabled={!trackInfo?.trackNumber || submitLoading}
              >
                {submitLoading ? (
                  <CircularProgress size={30} color={"secondary"} />
                ) : (
                  <>
                    <img src={search} alt="search_icon" />
                    {!isMobile && (
                      <span className={classes.track}>{t("main.track")}</span>
                    )}
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <AddOrderDialog open={openOrderDialog} setOpen={setOpenOrderDialog} />
      <OrderDetailsDialog
        open={
          !!(
            trackInfo.trackNumber &&
            trackInfo?.trackStatus?.length &&
            trackInfo.trackStatuses?.length
          )
        }
        setOpen={setTrackInfo}
        trackInfo={trackInfo}
      />
    </>
  );
};

export default Main;
