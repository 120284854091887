import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bus from "assets/shippingBuses.jpg";
import onlineShoping from "assets/onlineShoping.jpg";
import shipTo from "assets/shipToArmenia.jpg";
import more from "assets/icons/readMore.png";
import onlineShop from "assets/online_shop.jpg";
import warehouse from "assets/warehouse.jpg";
import { useState } from "react";
import SectionTitle from "components/SectionTitle";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  fixedWidthContainer: {
    scrollMargin: "100px",
    margin: "100px auto",
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
    },
  },
  card: {
    background: "#FFFFFF",
    borderRadius: 16,
    maxWidth: 397,
    overflow: "hidden",
    filter: "drop-shadow(0px 24px 64px rgba(0, 0, 0, 0.04))",
    width: "100%",
    whiteSpace: "pre-line",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto 15px",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 15,
    },
  },
  imageContainer: {
    padding: "15px 24px",
    display: "flex",
    height: "100%",
    minHeight: 100,
    justifyContent: "space-between",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minHeight: "unset",
    },
  },
  header: {
    paddingBottom: 10,
    textAlign: "start",
    fontWeight: 700,
    fontSize: 20,
  },
  cityContainer: {
    color: theme.palette.primary.contrastText,
  },
  img: {
    width: "100%",
    textAlign: "center",
    height: 215,
    "& img": {
      width: "100%",
      borderRadius: 10,
    },
    [theme.breakpoints.down("md")]: {
      height: "unset",
    },
  },
  cityNameContainer: {
    width: "100%",
    fontWeight: 700,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    fontSize: 20,
    color: "#FDFDFD",
    position: "relative",
    zIndex: 1,
  },
  cityName: {
    color: "#FFFFFF",
    bottom: 7,
    position: "absolute",
    background:
      " linear-gradient(179.26deg, rgba(10, 10, 10, 0) -5.71%, rgba(10, 10, 10, 0.365) 52.81%, rgba(10, 10, 10, 0.5) 99.36%)",
    width: "100%",
    borderRadius: " 0px 0px 10px 10px",
    height: 70,
    display: "flex",
    paddingLeft: 20,
    paddingTop: 20,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      paddingLeft: 10,
      paddingTop: 30,
    },
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 18,
    textAlign: "center",
    paddingBottom: 50,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
      paddingBottom: 15,
    },
  },
  description: {
    display: "flex",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: 1.5,
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    fontSize: 13,
  },
  readMore: {
    paddingBottom: 10,
    fontWeight: 700,
    fontSize: 16,
    textAlign: "right",
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    marginLeft: 10,
  },
  bookBtn: {
    fontSize: "18px",
    lineHeight: "25px",
    padding: "15px 20px",
    backgroundColor: "#fff",
    cursor: "pointer",
    "&.MuiButton-root": {
      height: 40,
      border: "2px solid #F1592A",
      borderRadius: 8,
      fontWeight: "normal",
      fontSize: 16,
      color: "black",
      textTransform: "unset!important",
    },
  },
}));

const ourServices = (t) => [
  {
    title: t("services.service1Title"),
    description: t("services.service1Description"),
    icon: bus,
  },
  {
    title: t("services.service2Title"),
    description: t("services.service2Description"),
    icon: shipTo,
  },
  {
    title: t("services.service3Title"),
    description: t("services.service3Description"),
    icon: warehouse,
  },
  {
    title: t("services.service4Title"),
    description: t("services.service4Description"),
    icon: onlineShoping,
  },
];

const Services = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState({});

  return (
    <Container
      maxWidth={"xl"}
      className={classes.fixedWidthContainer}
      id="services"
    >
      <Grid container spacing={2} className={classes.cityContainer}>
        <Grid item xs={12}>
          <SectionTitle title={t("services.title")} />
        </Grid>
        {ourServices(t).map((el, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              lg={3}
              key={idx}
              style={{
                height: "max-content",
              }}
            >
              <div className={classes.card}>
                <img src={el.icon} alt="" className={classes.img} />
                <div
                  className={classes.imageContainer}
                  style={{ maxHeight: open[idx] ? "unset" : 200 }}
                >
                  <div
                    style={{
                      maxHeight: open[idx] ? "unset" : 200,
                      overflow: "hidden",
                    }}
                  >
                    <div className={classes.header}>{el.title}</div>
                    <span
                      className={classes.description}
                      style={{ maxHeight: open[idx] ? "unset" : 80 }}
                    >
                      {open[idx] && el.description}
                    </span>
                  </div>

                  <div
                    className={classes.readMore}
                    onClick={() =>
                      setOpen((prev) => ({
                        ...prev,
                        [idx]: prev[idx] ? !prev[idx] : true,
                      }))
                    }
                  >
                    {t("global.readMore")}
                    <img
                      src={more}
                      alt="arrowRightIcon"
                      className={classes.icon}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Services;
