import React from "react";
import { makeStyles } from "@mui/styles";
// import Form2PackageInfo from "./Form2PackageInfo";
import QRCode from "react-qr-code";
import { currencyTypes } from "../../Parcel";
// import Form2PackageInfo from "./Form2PackageInfo";
const useStyeles = makeStyles((theme) => ({
  form1Sender: {
    width: "38%",
    textAlign: "left",
  },
  form1BarCode: {
    width: "35%",
    position: 'absolute',
    top: "0px",
    left: "0px",
    right: "0px",
    margin: "auto"
  },
  centerTitle: { fontSize: 13, fontWeight: 600, marginBottom: 1, whiteSpace: 'pre' },
  centerInfo: { fontSize: 13, fontWeight: 600, marginBottom: 2  },
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: 110,
    position: "relative",
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 5,
    marginTop: 8,
  },
  info: {
    borderBottom: "1px solid #eee",
    fontSize: 16,
    fontWeight: 900,
    height: "max-content",
    paddingBottom: 2,
    display: "flex",
    alignItems: "end",
  },
}));

const SenderRecieverForm2 = ({ data }) => {
  const classes = useStyeles();
  const senderRecieverKeys = [
    {
      key: "director_firstname",
      secondKey: "director_lastname",
      title: "Name",
      // customStyle: { fontWeight: 400, fontSize: 12 },
    },
    // {
    //   key: "customer_address",
    //   title: "Address",
    //   customStyle: { fontWeight: 400, fontSize: 12 },
    // },
    // {
    //   key: "postal_code",
    //   title: "Post index",
    //   customStyle: { fontWeight: 400, fontSize: 12 },
    // },
    // {
    //   key: "city",
    //   secondKey: "states_name",
    //   thirdKey: "country_name",
    //   title: "City/Region/Country",
    //   customStyle: { fontWeight: 400, fontSize: 12 },
    // },
    // {
    //   key: "email",
    //   title: "Email",
    //   customStyle: { fontWeight: 400, fontSize: 12 },
    // },
    // {
    //   key: "telephone",
    //   title: "Phone",
    //   customStyle: { fontWeight: 400, fontSize: 12 },
    // },
  ];

  return (
    <div className={classes.form1SenderReciver}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
          position: 'relative'
        }}
      >
        <div style={{ display: 'flex', width: "100%"}}>
          <div className={classes.form1Sender}>
            <div className={classes.sectionTitle}>From ID:{`${data.sender.country_code}${data.sender.contract_id}`}</div>
            {senderRecieverKeys.map((el, idx) => (
              <div
                key={idx}
                className={classes.info}
                style={el.customStyle && el.customStyle}
              >
                {el.title}: {""}
                {el.key && data?.sender[el.key]}
                {el.secondKey &&
                  (el.secondKey === "states_name" ? "/" : " ") +
                  (el.secondKey === "states_name"
                    ? data?.sender.country_code === "AM"
                      ? data?.sender[el.secondKey]
                      : data?.sender["other_states_name"]
                    : data?.sender[el.secondKey])}
                {el.thirdKey && "/" + data?.sender[el.thirdKey]}
              </div>
            ))}
            {data?.sender.taxpayer_id === "1" && (
              <div className={classes.info}>
                Company: {data?.sender.company_name}
              </div>
            )}
            <div className={classes.sectionTitle}>To ID:{`${data.receiver.country_code}${data.receiver.contract_id}`}</div>
            {senderRecieverKeys.map((el, idx) => (
              <div
                key={idx}
                className={classes.info}
                style={el.customStyle && el.customStyle}
              >
                {el.title}: {""}
                {el.key && data?.receiver[el.key]}
                {el.secondKey &&
                  (el.secondKey === "states_name" ? "/" : " ") +
                  (el.secondKey === "states_name"
                    ? data?.receiver.country_code === "AM"
                      ? data?.receiver[el.secondKey]
                      : data?.receiver["other_states_name"]
                    : data?.receiver[el.secondKey])}
                {el.thirdKey && "/" + data?.receiver[el.thirdKey]}
              </div>
            ))}
            {data?.receiver.taxpayer_id === "1" && (
              <div className={classes.info}>
                Company: {data?.receiver.company_name}
              </div>
            )}
          </div>
          <div className={classes.form1BarCode}>
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <div className={classes.centerTitle}>Total Price</div>
              <div className={classes.centerInfo}>
                {data?.parcel_discount
                  ? +data?.price -
                  +data?.local_delivery_price +
                  +data?.parcel_discount
                  : +data?.price - +data?.local_delivery_price}{" "}
                {currencyTypes[data.currency_type_id + "symbol"]}{" "}
                {data?.dimension_calculation === "1" ? (
                  <span style={{ opacity: 0.6 }}>v</span>
                ) : null}
              </div>
              {data?.local_delivery_price && +data?.local_delivery_price > 0 && (
                <>
                  <div className={classes.centerTitle}>Internal delivery</div>
                  <div className={classes.centerInfo}>
                    {data?.local_delivery_price} {currencyTypes[data.currency_type_id + "symbol"]}
                  </div>
                </>
              )}
              {data?.parcel_discount && data?.parcel_discount !== "0" ? (
                <>
                  <div className={classes.centerTitle}>Discount</div>
                  <div className={classes.centerInfo}>
                    {data?.parcel_discount} {currencyTypes[data.currency_type_id + "symbol"]}
                  </div>
                </>
              ) : null}
              {data?.local_delivery_price && +data?.local_delivery_price > 0 && (
                <>
                  <div className={classes.centerTitle}>To pay</div>
                  <div className={classes.centerInfo}>{data?.price} {currencyTypes[data.currency_type_id + "symbol"]}</div>
                </>
              )}
              {data?.payment_cash && data?.payment_cash > 0 && (
                <>
                  <div className={classes.centerTitle}>Cash paid</div>
                  <div className={classes.centerInfo}>{data?.payment_cash} {currencyTypes[data.currency_type_id + "symbol"]}</div>
                </>
              )}
              {data?.payment_bank_transfer && data?.payment_bank_transfer > 0 && (
                <>
                  <div className={classes.centerTitle}>Bank transfer</div>
                  <div className={classes.centerInfo}>
                    {data?.payment_bank_transfer} {currencyTypes[data.currency_type_id + "symbol"]}
                  </div>
                </>
              )}
              {data?.remainder && data?.remainder > 0 && (
                < >
                  <div className={classes.centerTitle}>
                    Payment in{' '}
                    {data?.sender.country_code === "AM" ? "EU" : "Armenia"}
                  </div>
                  <div className={classes.centerInfo}>{data?.remainder} {currencyTypes[data.currency_type_id + "symbol"]}</div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <Form2PackageInfo data={data} /> */}
        <QRCode
              value={`https://barekam.am/tracking/${data?.tracking_code}${data?.tracking_number}`}
              style={{ width: 140, height: 130, margin: "5px 0" }}
        />
      </div>
    </div>
  );
};

export default SenderRecieverForm2;
