import { Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import arm from "assets/icons/arm.png";
import pol from "assets/icons/pol.png";
import fb from "assets/icons/facebook_icn.png";
import instagram from "assets/icons/instagram_icn.png";
import call from "assets/icons/call.png";
import email from "assets/icons/email.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    scrollMargin: "100px",
    display: "flex",
    color: theme.palette.primary.contrastText,
    paddingTop: 58,
    paddingBottom: 30,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#F9F9F9",
    },
  },
  eachBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  contactBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: 60,
    [theme.breakpoints.down("lg")]: {
      paddingTop: 0,
    },
  },
  flag: {
    marginRight: 10,
  },
  cards: {
    marginRight: 14,
  },
  eachTitle: {
    fontWeight: 700,
    fontSize: 16,
    paddingBottom: 20,
    minHeight: 40,
  },
  eachCenter: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 20,
  },
  eachNum: {
    marginBottom: 15,
    display: "flex",
    alignItems: "start",
  },
  phone: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
  },
  contactIcon: {
    paddingRight: 10,
  },
  social: {
    marginRight: 15,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Contacts = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Container maxWidth={"xl"} className={classes.container} id="contacts">
      <Grid container spacing={2}>
        <Grid item lg={3} sm={6} xs={12}>
          <div className={classes.eachBlock}>
            <span className={classes.eachTitle}>
              {t("contacts.legalAddresses")}
            </span>
            <div style={{ textAlign: "initial" }}>
              {/* <div className={classes.eachCenter}>
                <div>
                  <img className={classes.flag} src={arm} alt="arm_flag" />
                </div>
                <span>{t("contacts.legalAddressArm")}</span>
              </div> */}
              <div className={classes.eachCenter}>
                <div>
                  <img className={classes.flag} src={pol} alt="pol_flag" />
                </div>
                <span>{t("contacts.legalAddressPol")}</span>
              </div>
            </div>
          </div>
          <div className={classes.eachBlock}>
            <span className={classes.eachTitle}>
              {t("contacts.centralAddresses")}
            </span>
            <div style={{ textAlign: "initial" }}>
              <div className={classes.eachCenter}>
                <div>
                  <img className={classes.flag} src={arm} alt="arm_flag" />
                </div>
                <div>
                </div>
                <div style={{display: 'flex', flexDirection:"column"}}>

                <span>{t("contacts.centralAddressArm")}</span>
                <span style={{fontSize: "14px"}}>{t("contacts.workingSchedule")} 10։00-18։30</span>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <div className={classes.eachBlock}>
            <span className={classes.eachTitle}>{t("contacts.contact")}</span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <div className={classes.eachNum}>
                <img className={classes.contactIcon} src={call} alt="call" />
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.armenia")}
                    </span>
                    <a href="tel:+37460270727" className={classes.phone}>
                      +374 60 27 07 27
                    </a>
                    <div>
                      <a href="tel:+37477145462" className={classes.phone}>
                        +374 77 14 54 62
                      </a>{" "}
                      (Viber, WhatsApp)
                    </div>
                    <div>
                      <a href="tel:+37491145464" className={classes.phone}>
                        +374 91 14 54 64
                      </a>{" "}
                      (Viber, WhatsApp)
                    </div>
                    <a href="tel:+37493896999" className={classes.phone}>
                      +374 93 89 69 99 (Viber, WhatsApp) <br />
                      {t("contacts.armeniaContactText")}
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.poland")}
                    </span>
                    <a href="tel:+48585859099" className={classes.phone}>
                      +48-585-859-099
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.germany")}
                    </span>
                    <a href="tel:+491773394384" className={classes.phone}>
                      +49-177-339-43-84
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.france")}
                    </span>
                    <a href="tel:+33755539233" className={classes.phone}>
                      +33-755-53-92-33
                    </a>
                    <div>
                      <a href="tel:+33658114249" className={classes.phone}>
                        +33-658-11-42-49
                      </a>{" "}
                      ({t("contacts.marcel")})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <div className={classes.eachBlock}>
            <div className={classes.contactBlock}>
              <div className={classes.eachNum}>
                <img className={classes.contactIcon} src={call} alt="call" />
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.belgium")}
                    </span>
                    <a href="tel:+32466900908" className={classes.phone}>
                      +32-466-90-09-08
                    </a>
                    <a href="tel:+32456089755" className={classes.phone}>
                      +32-456-08-97-55
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {t("contacts.netherlands")}
                    </span>
                    <a href="tel:+3197010281400" className={classes.phone}>
                      +31-970-10-28-14-00
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: 5,
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    >
                      {`${t("contacts.bulgaria")} / ${t('contacts.spain')}`}
                    </span>
                    <a href="tel:+359895833889" className={classes.phone}>
                      +359-895-83-38-89
                    </a>
                  </div>
                </div>
              </div>
              <div className={classes.eachCenter}>
                <div>
                  <img
                    className={classes.contactIcon}
                    src={email}
                    alt="email"
                  />
                </div>
                <a
                  href="mailto:contact@barekam.am"
                  style={{ color: "#26341F", textDecoration: "none" }}
                >
                  contact@barekam.am
                </a>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <div className={classes.eachBlock}>
            <span className={classes.eachTitle}>{t("contacts.followUs")}</span>
            <div>
              <a
                href="https://www.facebook.com/BLSBarekamLogisticService"
                target="_blank"
                rel="noreferrer"
              >
                <img className={classes.social} src={fb} alt="facebook_icon" />
              </a>
              <a
                href="https://www.instagram.com/barekam.logistic.service/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={classes.social}
                  src={instagram}
                  alt="instagram_icon"
                />
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contacts;
