import React from "react";
import { makeStyles } from "@mui/styles";
const useStyeles = makeStyles((theme) => ({
  form1SenderReciver: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    position: "relative",
  },
  formText: {
    paddingTop: 10,
    textAlign: "left",
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 900,
    textAlign: "left",
    marginBottom: 5,
  },
  info: {
    borderBottom: "1px solid #eee",
    fontSize: 13,
    fontWeight: 400,
    height: "max-content",
    paddingBottom: 2,
    display: "flex",
    alignItems: "end",
  },
}));

const SenderReciver = ({ data }) => {
  const classes = useStyeles();
  const senderRecieverKeys = [
    {
      key: "director_firstname",
      secondKey: "director_lastname",
      title: "Name",
    },
    { key: "customer_address", title: "Address" },
    {
      key: "postal_code",
      title: "Post index",
      customStyle: { fontWeight: 400, fontSize: 11 },
    },
    {
      key: "city",
      secondKey: "states_name",
      thirdKey: "country_name",
      title: "City/Region/Country",
      customStyle: { fontWeight: 400, fontSize: 11 },
    },
    {
      key: "email",
      title: "Email",
      customStyle: { fontWeight: 400, fontSize: 11 },
    },
    { key: "telephone", title: "Phone" },
  ];
  return (
    <div className={classes.form1SenderReciver}>
      <div className={classes.formText}>
        <div className={classes.sectionTitle}>Sender</div>
        {senderRecieverKeys.map((el, idx) => (
          <div
            key={idx}
            className={classes.info}
            style={el.customStyle && el.customStyle}
          >
            {el.title}: {el.key && data?.sender[el.key]}
            {el.secondKey &&
              (el.secondKey === "states_name" ? "/" : " ") +
                (el.secondKey === "states_name"
                  ? data?.sender.country_code === "AM"
                    ? data?.sender[el.secondKey] ?? '-'
                    : data?.sender["other_states_name"] ?? '-'
                  : data?.sender[el.secondKey] ?? '-')}
            {el.thirdKey && "/" + data?.sender[el.thirdKey]}
          </div>
        ))}
        {data?.sender.taxpayer_id === "1" && (
          <div className={classes.info}>
            Company: {data?.sender.company_name}
          </div>
        )}
        {/* <div className={classes.info}>
          Name:{" "}
          {`${data?.sender.director_firstname} ${data?.sender.director_lastname}`}
        </div>
        <div className={classes.info}>
          Address: {data?.sender.customer_address}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div
            className={classes.info}
            style={{ width: "32%", fontWeight: 400, fontSize: 12 }}
          >
            Post index: {data?.sender.postal_code}
          </div>
          <div
            className={classes.info}
            style={{ width: "65%", fontWeight: 400, fontSize: 12 }}
          >
            City/Country: {`${data?.sender.city} ${data?.sender.country_name}`}
          </div>
        </div>
        <div className={classes.info} style={{ fontWeight: 400, fontSize: 13 }}>
          Email: {data?.sender.email}
        </div>
        <div className={classes.info}>Phone: {data?.sender.telephone}</div> */}
      </div>
      <div className={classes.formText} style={{ marginTop: 3 }}>
        <div className={classes.sectionTitle}>Reciever</div>
        {senderRecieverKeys.map((el, idx) => (
          <div
            key={idx}
            className={classes.info}
            style={el.customStyle && el.customStyle}
          >
            {el.title}: {el.key && data?.receiver[el.key]}
            {el.secondKey &&
              (el.secondKey === "states_name" ? "/" : " ") +
                (el.secondKey === "states_name"
                  ? data?.receiver.country_code === "AM"
                    ? data?.receiver[el.secondKey] ?? '-'
                    : data?.receiver["other_states_name"] ?? '-'
                  : data?.receiver[el.secondKey] ?? '-')}
            {el.thirdKey && "/" + data?.receiver[el.thirdKey]}
          </div>
        ))}
        {data?.receiver.taxpayer_id === "1" && (
          <div className={classes.info}>
            Company: {data?.receiver.company_name}
          </div>
        )}
        {/* <div className={classes.info}>
          Name:{" "}
          {`${data?.receiver.director_firstname} ${data?.receiver.director_lastname}`}
        </div>
        <div className={classes.info}>
          Address: {data?.receiver.customer_address}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <div
            className={classes.info}
            style={{ width: "32%", fontWeight: 400, fontSize: 12 }}
          >
            Post index: {data?.receiver.postal_code}
          </div>
          <div
            className={classes.info}
            style={{ width: "65%", fontWeight: 400, fontSize: 12 }}
          >
            City/Country:{" "}
            {`${data?.receiver.city} ${data?.receiver.country_name}`}
          </div>
        </div>
        <div className={classes.info} style={{ fontWeight: 400, fontSize: 13 }}>
          Email: {data?.receiver.email}
        </div>
        <div className={classes.info}>Phone: {data?.receiver.telephone}</div> */}
      </div>
    </div>
  );
};

export default SenderReciver;
