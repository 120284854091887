import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import "react-phone-number-input/style.css";
import { useContext, useEffect, useState } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SettingsContext } from "context/Settings.context";
import SERVICES from "services";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    "& .MuiPaper-root": {
      borderRadius: 12,
      width: "50%",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiTypography-root": {
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    "&.MuiDialogContent-root": {
      padding: "45px 40px",
      [theme.breakpoints.down("lg")]: {
        padding: 20,
      },
    },
  },
  titleBlock: {
    paddingBottom: 40,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      alignItems: "baseline",
      paddingBottom: 20,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontWeight: 700,
    fontSize: 36,
    textAlign: "center",
    lineHeight: 2,
    paddingLeft: 25,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("lg")]: {
      justifyContent: "start",
      paddingLeft: 0,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  closeIcon: {
    border: "2px solid",
    borderRadius: "50%",
    cursor: "pointer",
  },

  inputs: {
    display: "flex",
    flexDirection: "column",
  },
  inputTitle: {
    fontWeight: 1000,
    lineHeight: 2,
    color: "#252733",
    fontSize: 16,
    letterSpacing: "0.4px",
  },

  password: {
    "&.MuiFormControl-root": {
      marginBottom: 10,
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiFormLabel-asterisk": {
      color: "#FF0B21",
      fontSize: 18,
      fontWeight: 700,
    },
    // "& .MuiOutlinedInput-notchedOutline": {
    //   borderColor: theme.palette.primary.main,
    // },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
  },
  required: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 20,
    letterSpacing: "0.4px",
  },
  asterisk: {
    fontWeight: 700,
    marginRight: 10,
    letterSpacing: "0.4px",
    color: "#FF0B21",
  },
  btnSection: {
    display: "flex",
    justifyContent: "center",
  },
  nextBtn: {
    "&.MuiButtonBase-root": {
      textTransform: "unset",
      background: theme.palette.primary.main,
      color: "white",
      marginTop: 50,
      borderRadius: 12,
      fontWeight: 700,
      fontSize: 22,
      padding: "5px 30px",
      width: "30%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      "&:hover": {
        background: theme.palette.primary.main,
      },
    },
  },
}));

const initialForm = {
  old_password: "",
  password: "",
  c_password: "",
};

const ChangePasswordDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [user, setUser] = useState(initialForm);
  const { dispatch } = useContext(SettingsContext);
  const [inputErrors, setInputErrors] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setInputErrors(null);
    setOpen(false);
    setUser(initialForm);
  };

  const handleChange = (event) => {
    setInputErrors(null);
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = () => {
    SERVICES.AUTH.changePass(user)
      .then((res) => {
        dispatch({
          type: "SUCCESS_TOAST",
          payload: { message: "Success action" },
        });
        handleClose();
      })
      .catch((err) => {
        if (typeof err.message !== "string") {
          setInputErrors(err.message);
        }
      });
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== user.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("passwordContains", (value) => {
      const regexp = new RegExp(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,}$/
      );
      return regexp.test(value);
    });

    return () => {
      ValidatorForm.removeValidationRule("isPasswordMatch");
      ValidatorForm.removeValidationRule("passw");
    };
  }, [user.password, user.c_password]);

  const handleClickShowPassword = (setValue, value) => {
    setValue(!value);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.content}>
        <div className={classes.titleBlock}>
          <div className={classes.title}>Change password</div>
          <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
        >
          <div className={classes.inputs}>
            <span className={classes.inputTitle}>
              Old password <span className={classes.asterisk}>*</span>
            </span>
            <TextValidator
              className={classes.password}
              error={!!inputErrors?.old_password}
              helperText={inputErrors?.old_password}
              fullWidth
              margin="dense"
              label=""
              onChange={handleChange}
              name="old_password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              validators={["required", "minStringLength:6"]}
              errorMessages={[t("global.required"), "Min 6 characters"]}
              value={user.old_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(setShowPassword, showPassword)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <span className={classes.inputTitle}>
              New password <span className={classes.asterisk}>*</span>
            </span>
            <TextValidator
              className={classes.password}
              error={!!inputErrors?.password}
              helperText={inputErrors?.password}
              fullWidth
              margin="dense"
              label=""
              onChange={handleChange}
              name="password"
              autoComplete="new-password"
              type={showNewPassword ? "text" : "password"}
              validators={["required", "minStringLength:8", "passwordContains"]}
              errorMessages={[
                t("global.required"),
                "Min 8 characters",
                "Wrong format of password, it must contain more than 8 characters, at least 1 number, at least 1 special character, and at least 1 capital. ",
              ]}
              value={user.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(
                          setShowNewPassword,
                          showNewPassword
                        )
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <span className={classes.inputTitle}>
              Confirm new password <span className={classes.asterisk}>*</span>
            </span>
            <TextValidator
              className={classes.password}
              error={!!inputErrors?.c_password}
              helperText={inputErrors?.c_password}
              fullWidth
              margin="dense"
              label=""
              onChange={handleChange}
              name="c_password"
              autoComplete="confirm-password"
              type={showConfirmPassword ? "text" : "password"}
              validators={[
                "required",
                "minStringLength:8",
                "passwordContains",
                "isPasswordMatch",
              ]}
              errorMessages={[
                t("global.required"),
                "Min 8 characters",
                "Wrong format of password, it must contain more than 8 characters, at least 1 number, at least 1 special character, and at least 1 capital. ",
                "Passwords must match",
              ]}
              value={user.c_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleClickShowPassword(
                          setShowConfirmPassword,
                          showConfirmPassword
                        )
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={classes.required}>
            <span className={classes.asterisk}>*</span>
            Required field
          </div>
          <div className={classes.btnSection}>
            <Button
              variant="contained"
              className={classes.nextBtn}
              type="submit"
            >
              Done
            </Button>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordDialog;
