import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  search: {
    margin: "20px 0px",
    display: "flex",
    width: "100% !important",
    border: "1px solid #13A800",
    borderRadius: "12px  !important",
    "&.MuiPaper-root": {
      boxShadow: "unset",
      boxSizing: "border-box",
    },
    "& .MuiIconButton-root": {
      color: "#A7A7A7",
    },
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  }

  }),
);
